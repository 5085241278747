import React, { Component } from 'react'

class Works01 extends Component {
    
    render() {
        let pageText = {
            Header1: "โครงการพัฒนาระบบวิเคราะห์ความเสี่ยงตามหลักธรรมาภิบาลของแผนงาน/โครงการที่ของบประมาณแผ่นดิน",
            Header2: "สำนักงบประมาณ สำนักนายกรัฐมนตรี",
            MainContent: "ระบบนี้เป็น Web-based system สำหรับให้ผู้ทำคำของบประมาณของหน่วยราชการต่าง ๆ ประเมินความเสี่ยงของแผนงาน/โครงการที่ขอใช้งบประมาณแผ่นดิน เพื่อประกอบการพิจารณาการอนุมัติงบประมาณ โดยใช้ ASP.Net MVC, C#, NHibernate, Microsoft SQL Server ความพิเศษของระบบนี้คือแบบประเมินไม่ตายตัว เนื่องจากแบบประเมินเก็บอยู่ในฐานข้อมูล ไม่ได้ฝังตัวอยู่ในโปรแกรม สามารถเปลี่ยนแปลงได้ แต่เนื่องจากข้อจำกัดด้านเวลาพัฒนา ในขอบเขตของโครงการจึงไม่ได้ระบุให้มีหน้าจอสำหรับสร้างและปรับปรุงแบบประเมิน ประเภทคำถามในแบบประเมินที่ระบบรองรับได้มีหลากหลาย เช่น",
            SubContent1: "คำถามปรนัยที่ต้องการคำตอบเป็น วันเวลา เลขจำนวนเต็ม หรือข้อความ เป็นต้น",
            SubContent2: "คำถามอัตนัยที่กำหนดให้ผู้ตอบเลือกหนึ่งคำตอบ หรือหลายคำตอบจากชุดคำตอบที่กำหนดไว้ให้เลือก นอกจากนี้แต่ละคำตอบ ผู้สร้างแบบประเมินสามารถกำหนดให้มีคำถามเพิ่มเติม ได้อีกหลายคำถาม ในกรณีที่คำตอบนั้นถูกเลือก",
            SubContent3: "คำถามอัตนัยที่ให้ผู้ตอบเลือกได้หลายคำตอบจากรายการคำตอบที่กำหนดไว้",
            SubContent4: "คำถามแบบชุด (Matrix) หรือแบบตารางซึ่งประกอบประเด็นหลายข้อ (ประเด็นละหนึ่งแถว) แต่ละประเด็นจะมีชุดคำถาม (มีตั้งแต่หนึ่งคำถามขึ้นไป) ที่เหมือนกัน (หนึ่งคำถามเป็นหนึ่งสดมภ์)",
            FigureDescription1: "An example of the matrix question",
            FigureDescription2: "A partial class diagram (Microsoft Visual Studio style) of the questionnaire classes",
        };


        return (
            <div className="pl-md-5 pt-5" style={{width:"100%", height:"100%", backgroundColor: "white"}}>
                <h5 className="isabaya-font-green">{pageText.Header1}</h5>
                <h6 className="isabaya-font-green">{pageText.Header2}</h6>
                <br />
                <span className="d-block">{pageText.MainContent}</span>
                <br />
                <ul className="isabaya">
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                    <li><span>{pageText.SubContent4}</span></li>
                </ul>
                <br />
                <div className="p-3 m-auto text-center" style={{border: "2px solid rgb(156,148,1)", width:"95%"}}>
                    <img alt="" className="d-block img-fluid" style={{marginBottom: "5px"}} src={process.env.PUBLIC_URL + "/image/works0001.png"} />
                    <span style={{fontSize: "0.85rem"}}>{pageText.FigureDescription1}</span>
                </div>
                <br />
                <div className="p-3 m-auto text-center" style={{border: "2px solid rgb(156,148,1)", width:"95%"}}>
                    <img alt="" className="d-block img-fluid" style={{marginBottom: "5px"}} src={process.env.PUBLIC_URL + "/image/works0002.png"} />
                    <span style={{fontSize: "0.85rem"}}>{pageText.FigureDescription2}</span>
                </div>
                <br />
                <br />
            </div>
        );
    }
}

export default Works01