import React, { Component } from 'react'

class Works07 extends Component {

    render() {
        let pageText = {
            Header1: "โครงการพัฒนาระบบโอนเงินพร้อมเพย์",
            Header2: "ธนาคาร เกียรตินาคิน จำกัด (มหาชน)",
            MainContent: "โครงการพัฒนาระบบโอนเงินพร้อมเพย์ (PromptPay) (ใช้ ASP.Net MVC, C#, NHibernate, Oracle) ระบบนี้ประกอบด้วย",
            SubContent1: "SOAP-based Web Services สำหรับให้ระบบ Front office ของธนาคารส่งคำสั่งโอนเงินโดยใช้ หมายเลขประจำตัวประชาชน และ/หรือ หมายเลขโทรศัพท์เคลื่อนที่ ที่ขึ้นทะเบียนไว้กับธนาคารอื่น",
            SubContent2: "ระบบ Transfer Gateway ทำหน้าที่ทั้งรับและส่งธุรกรรมการเงินกับระบบทะเบียนกลางของ บริษัท National ITMX จำกัด โดยใช้ ISO 8583 message (ผ่าน TCP/IP socket) ซึ่งเราได้พัฒนาขึ้นให้นำมาใช้ใหม่ได้ (Reusable)",
            FigureDescription1: "A partial class diagram (in Microsoft Visual Studio style) of the classes for ISO8583 Message",
        };


        return (
            <div className="pl-md-5 pt-5" style={{width:"100%", height:"100%", backgroundColor: "white"}}>
                <h5 className="isabaya-font-green">{pageText.Header1}</h5>
                <h6 className="isabaya-font-green">{pageText.Header2}</h6>
                <br />
                <span className="d-block">{pageText.MainContent}</span>
                <br />
                <ul className="isabaya">
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                </ul>
                <br />
                <div className="p-3 m-auto text-center" style={{border: "2px solid rgb(156,148,1)", width:"95%"}}>
                    <img alt="" className="d-block img-fluid" style={{marginBottom: "5px"}} src={process.env.PUBLIC_URL + "/image/works0006.png"} />
                    <span style={{fontSize: "0.85rem"}}>{pageText.FigureDescription1}</span>
                </div>
                <br />
                <br />
            </div>
        );
    }
}

export default Works07