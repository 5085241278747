import React, { Component } from 'react'

class ContentBox extends Component {
    render() {
      return (
          <div style={{paddingTop: "15px", paddingBottom: "15px"}}>
              <img alt="" className="img-fluid" src={this.props.ImgSrc} />
              <br />
              <br />
              <h5 style={{padding: "0px 10px 0px 10px"}} className="isabaya-font-green">{this.props.Title}</h5>
              <p style={{fontSize: "14px", padding: "0px 10px 0px 10px"}}>{this.props.Content}</p>
          </div>
      );
    }
  }

  export default ContentBox