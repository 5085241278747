import React, { Component } from 'react'

class Products08 extends Component {

    render() {
        let pageText = {
            Header: "ระบบการลา",
            MainContent: "",
            SubContent1: "สามารถกำหนดข้อมูลต่างๆ เช่น ประเภทการลา ปฏิทินวันหยุดประจำ ผู้รับผิดชอบในการบันทึกข้อมูลการมาปฏิบัติงานและการลา พนักงานประจำด่าน-กลุ่มงาน เป็นต้น ตัวอย่างความสามารถของระบบมีดังนี้",
            SubContent2: "สามารถกำหนดประเภทการลาเช่น ลาป่วย, ลากิจ, ลาพักร้อน, ลาบวช เป็นต้น",
            SubContent3: "สามารถกำหนดว่าให้การลาแต่ละประเภทมีจำนวนวันลาสูงสุด จำนวนครั้งที่ลาสูงสุด จำนวนวันลาต่อครั้งมากสุดได้ และจำนวนวันลาอย่างน้อยต่อครั้ง",
            SubContent4: "สามารถทำการปรับจำนวนวันลาพักร้อน ตามอายุการทำงานโดยอัตโนมัติและกำหนดจำนวนวันลาในรอบ 1 ปีของแต่ละคนได้",
            SubContent5: "สามารถเชื่อมต่อกับระบบตารางเวลาการทำงาน เพื่อตรวจสอบความผิดปกติของข้อมูลเวลาการทำงานกับข้อมูลการลาของบุคลากรเช่น มาทำงานแต่ส่งใบลา หรือขาดงานแต่ไม่ส่งใบลา เป็นต้น",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <ul className="isabaya">
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                    <li><span>{pageText.SubContent4}</span></li>
                    <li><span>{pageText.SubContent5}</span></li>
                </ul>
                <br />
                <br />
                <img alt="" className="img-fluid" style={{display: "block", margin: "0 auto"}} src={process.env.PUBLIC_URL + "/image/products0008.jpg"} />
            </div>
        );
    }
}

export default Products08