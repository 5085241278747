import React, { Component } from 'react'

class Products14 extends Component {

    render() {
        let pageText = {
            Header: "ระบบงานคำสั่ง(Executive Order)",
            MainContent: "",
            SubContent1: "สามารถสรุปบันทึก/เปลี่ยนแปลงเกณฑ์การพิจารณาการเลื่อนระดับของพนักงาน โดยผู้ใช้งานระบบ",
            SubContent2: "สามารถประมวลผลเพื่อหาพนักงานที่มีสิทธิเลื่อนระดับ ตามเกณฑ์ที่กำหนดและแก้ไขปรับปรุงสิทธิในการเลื่อนระดับได้",
            SubContent3: "สามารถบันทึกข้อมูล(ร่าง)คำสั่งประเภทต่างๆและจัดพิมพ์เอกสารคำสั่งตามรูปแบบมาตรฐาน เพื่อใช้นำเสนอผู้มีอำนาจลงนาม โดยข้อมูลเบื้องต้นมาจากงานต่างๆที่เกี่ยวข้อง",
            SubContent4: "สามารถประมวลผลข้อมูลคำสั่งที่ได้รับอนุมัติแล้ว เพื่อปรับปรุงข้อมูลอื่นที่เกี่ยวข้องให้โดยอัตโนมัติ",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <ul className="isabaya">
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                    <li><span>{pageText.SubContent4}</span></li>
                </ul>
                <br />
                <br />
                <img alt="" className="img-fluid" style={{display: "block", margin: "0 auto"}} src={process.env.PUBLIC_URL + "/image/products0014.jpg"} />
            </div>
        );
    }
}

export default Products14