import React, { Component } from 'react'
import BannerWidth10 from '../components/BannerWidth10'
import NavbarWokes from '../components/NavbarWokes'
import Works00 from '../pages/works_detail/Works00'
import Works01 from '../pages/works_detail/Works01'
import Works02 from '../pages/works_detail/Works02'
import Works03 from '../pages/works_detail/Works03'
import Works04 from '../pages/works_detail/Works04'
import Works05 from '../pages/works_detail/Works05'
import Works06 from '../pages/works_detail/Works06'
import Works07 from '../pages/works_detail/Works07'
import Works08 from '../pages/works_detail/Works08'


class Works extends Component {

    render() {
        const pageIndexToDisplay = this.props.PageIndexToDisplay;
        let page;
        if(pageIndexToDisplay === "01") { page = <Works01 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "02") { page = <Works02 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "03") { page = <Works03 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "04") { page = <Works04 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "05") { page = <Works05 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "06") { page = <Works06 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "07") { page = <Works07 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "08") { page = <Works08 lang={this.props.lang} /> }
        else { page = <Works00 lang={this.props.lang} /> }

        
        return (
            <div>
                <BannerWidth10 Image={process.env.PUBLIC_URL + "/image/banner_works0001.png"} />
                <div className="row d-md-none" style={{ backgroundColor: "rgb(244,244,244)" }}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10"><NavbarWokes lang={this.props.lang} /></div>
                    <div className="col-sm-1"></div>
                </div>
                <div className="row" style={{ backgroundColor: "rgb(244,244,244)" }}>
                    <div className="col-sm-1 col-md-1"></div>
                    <div className="col-md-3 d-none d-md-block pr-md-0"><NavbarWokes lang={this.props.lang} /></div>
                    <div className="col-sm-10 col-md-7 pl-md-0">
                        {page}
                    </div>
                    <div className="col-sm-1 col-md-1"></div>
                </div>
            </div>
        );
    }
}

export default Works