import React, { Component } from 'react'
import BannerWidth10 from '../components/BannerWidth10'

class Services extends Component {

    render() {
        let serviceName1 = "";
        let serviceName2 = "";
        let serviceName3 = "";
        let serviceName4 = "";
        let serviceName5 = "";
        if (this.props.lang === "en-US") {
            serviceName1 = "Enterprise - level information system designand development";
            serviceName2 = "Strategic information system planning";
            serviceName3 = "Consulting on object-oriented design and development, NHibernate ORM, and database design & training";
            serviceName4 = "Design and develop Data Warehouse system using Microsoft SQL Server Integration Services (SSIS)";
            serviceName5 = "Training in object-oriented software design using UML, NHibernate";
        }
        else {
            serviceName1 = "ออกแบบและพัฒนาระบบสารสนเทศระดับ Enterprise";
            serviceName2 = "วางแผนยุทธศาสตร์ระบบสารสนเทศ";
            serviceName3 = "ออกแบบและพัฒนาระบบคลังข้อมูล (Data warehouse) โดยใช้ Microsoft SQL Server Integration Services (SSIS)";
            serviceName4 = "อบรมการออกแบบซอฟท์แวร์เชิงวัตถุด้วย UML และการใช้ NHibernate";
            serviceName5 = "รับปรึกษาด้านการออกแบบและพัฒนาซอฟท์แวร์เชิงวัตถุ (Object-Relation Mapping) และการออกแบบฐานข้อมูลการใช้ NHibernate ORM และปรับสมรรถนะฐานข้อมูล";
        }
        

        return (
            <div>
                <BannerWidth10 Image={process.env.PUBLIC_URL + "/image/banner_services0001.png"} />
                <div className="row" style={{ backgroundColor: "rgb(244,244,244)" }}>
                  <div className="col-sm-1"></div>
                  <div className="col-sm-5 pt-5">
                      <div style={{maxWidth: "460px", marginLeft: "auto", marginRight: "auto"}}>
                          <img alt="" className="img-fluid" src={process.env.PUBLIC_URL + "/image/StockSnap_B6GYMTH73U.jpg"} />
                          <ul className="isabaya-font-green">
                              <li>{serviceName1}</li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-sm-5 pt-5">
                      <div style={{maxWidth: "460px", marginLeft: "auto", marginRight: "auto"}}>
                          <img alt="" className="img-fluid" src={process.env.PUBLIC_URL + "/image/StockSnap_J062QXCJVF.jpg"} />
                          <ul className="isabaya-font-green">
                              <li>{serviceName2}</li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
                <div className="row" style={{ backgroundColor: "rgb(244,244,244)" }}>
                  <div className="col-sm-1"></div>
                  <div className="col-sm-5 pt-5">
                      <div style={{maxWidth: "460px", marginLeft: "auto", marginRight: "auto"}}>
                          <img alt="" className="img-fluid" src={process.env.PUBLIC_URL + "/image/StockSnap_S6L2DPGXUW.jpg"} />
                          <ul className="isabaya-font-green">
                              <li>{serviceName3}</li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-sm-5 pt-5">
                      <div style={{maxWidth: "460px", marginLeft: "auto", marginRight: "auto"}}>
                          <img alt="" className="img-fluid" src={process.env.PUBLIC_URL + "/image/StockSnap_OR8Z2Z6Z48.jpg"} />
                          <ul className="isabaya-font-green">
                              <li>{serviceName4}</li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
                <div className="row" style={{ backgroundColor: "rgb(244,244,244)" }}>
                  <div className="col-sm-1"></div>
                  <div className="col-sm-10 pt-5">
                      <div style={{maxWidth: "460px", marginLeft: "auto", marginRight: "auto"}}>
                          <img alt="" className="img-fluid" src={process.env.PUBLIC_URL + "/image/StockSnap_JBW2PXDOL6.jpg"} />
                          <ul className="isabaya-font-green">
                              <li>{serviceName5}</li>
                          </ul>
                      </div>
                      <br />
                      <br />
                  </div>
                  <div className="col-sm-1"></div>
                </div>
            </div>
        );
    }
}

export default Services
