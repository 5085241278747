import React, { Component } from 'react'

class Products17 extends Component {

    render() {
        let pageText = {
            Section1:{
                Header: "ระบบทะเบียนกองทุนสำรองเลี้ยงชีพ",
                MainContent: "",
                SubContent1: "สามารถประมวลผลธุรกรรมกองทุนสำรองเลี้ยงชีพ เช่น",
                SubContent1_1: "ธุรกรรมนำเข้าเงินสำรองเลี้ยงชีพ",
                SubContent1_2: "ธุรกรรมการพ้นสภาพของสมาชิก ทั้งรับเงินทั้งหมด คงเงินและรับภายหลัง",
                SubContent1_3: "ธุรกรรมคืนเงินสำรองเลี้ยงชีพส่วนเกิน",
                SubContent1_4: "ฯลฯ",
                SubContent2: "สามารถรองรับกองทุนสำรองเลี้ยงชีพแบบ",
                SubContent2_1: "Master fund – Sub-fund",
                SubContent2_2: "Pool fund",
                SubContent3: "สามารถกำหนดแผนกระจายการลงทุนรายสมาชิก",
                SubContent4: "มีเครื่องมือกำหนดรูปแบบของแฟ้มข้อมูลเงินสำรองเลี้ยงชีพ รองรับรูปแบบของแฟ้มข้อมูลเงินสำรองเลี้ยงชีพ",
                SubContent5: "สามารถสร้างรายการชำระเงินให้สมาชิกกองทุนสำรองเลี้ยงชีพ ด้วยเช็ค หรือวิธีอื่น ๆ โดยอัตโนมัติตาม Business rule ของ บลจ. เพื่อลดค่าใช้จ่ายในการชำระเงิน",
            },
            Section2: {
                Header: "",
                MainContent: "",
                SubContent1: "Microsoft SQL Server 2008",
                SubContent2: "Microsoft Windows Server 2003 or 2008",
            },
        };
        if(this.props.lang === "en-US") {
            pageText.Section2.Header = "System Requirements";
        }
        else {
            pageText.Section2.Header = "ความต้องการของระบบ";
        }


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Section1.Header}</h5>
                <ul className="isabaya">
                    <li>
                        <span>{pageText.Section1.SubContent1}</span>
                        <ol style={{marginTop: "15px"}}>
                            <li style={{marginBottom: "10px"}}>{pageText.Section1.SubContent1_1}</li>
                            <li style={{marginBottom: "10px"}}>{pageText.Section1.SubContent1_2}</li>
                            <li style={{marginBottom: "10px"}}>{pageText.Section1.SubContent1_3}</li>
                            <li style={{marginBottom: "10px"}}>{pageText.Section1.SubContent1_4}</li>
                        </ol>
                    </li>
                    <li>
                        <span>{pageText.Section1.SubContent2}</span>
                        <ol style={{marginTop: "15px"}}>
                            <li style={{marginBottom: "10px"}}>{pageText.Section1.SubContent2_1}</li>
                            <li style={{marginBottom: "10px"}}>{pageText.Section1.SubContent2_2}</li>
                        </ol>
                    </li>
                    <li><span>{pageText.Section1.SubContent3}</span></li>
                    <li><span>{pageText.Section1.SubContent4}</span></li>
                    <li><span>{pageText.Section1.SubContent5}</span></li>
                </ul>
                <br />
                <br />
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Section2.Header}</h5>
                <ul className="isabaya">
                    <li><span>{pageText.Section2.SubContent1}</span></li>
                    <li><span>{pageText.Section2.SubContent2}</span></li>
                </ul>
            </div>
        );
    }
}

export default Products17