import React, { Component } from 'react'

class Logo extends Component {
    render() {
      return (
        <div className="row" style={{backgroundColor: "rgb(244,244,244)"}}>
          <div className="col-sm-1"></div>
          <div className="col-sm-10 text-center text-sm-left">
            <img alt="" className="img-fluid" src={process.env.PUBLIC_URL + "/image/new_isabaya_logo.png"} />
          </div>
          <div className="col-sm-1"></div>
        </div>
      );
    }
  }

  export default Logo