import React, { Component } from 'react'

class Products06 extends Component {

    render() {
        let pageText = {
            Section1: {
                Header: "ระบบบริหารเวลาปฏิบัติงาน Window application (Time Administration Module)",
                MainContent: "เป็นระบบย่อยของ HRNet ที่ช่วยในการกำหนด วางแผน และควบคุมเวลาการปฏิบัติงานและเรื่องการมาทำงานของบุคลากรได้อย่างมีประสิทธิภาพ โดยระบบบริหารเวลาการปฏิบัติงานจะเป็นตัวจัดการเกี่ยวกับรูปแบบของตารางการทำงานแบบต่าง ๆ ของบริษัทและทำการจัดสรรบุคลากรลงในตารางการทำงานนั้น ๆ พร้อมทั้งทำการคำนวณเวลาปฏิบัติงานของพนักงานเพื่อนำไปใช้กับระบบอื่น ๆ ต่อไป เช่น ระบบเงินเดือน เป็นต้น ตัวอย่างความสามารถของระบบมีดังนี้",
            },
            Section2: {
                Header: "ระบบตารางเวลาการทำงาน",
                MainContent: "",
                SubContent1: "สามารถสร้างตารางเวลาการทำงานได้หลายแบบ โดยจะสามารถจำแนกประเภทของตารางเวลาการทำงานนั้นตามประเภทตารางการทำงาน เช่น ตารางการทำงานปกติ ตารางการทำงานล่วงเวลา (OT) และตารางการเดินทาง เป็นต้น ตารางการทำงานนั้นมีการจ่ายเงินในแบบใด เช่น จ่ายเป็นเงินเดือนปกติ หรือเป็นค่าล่วงเวลา",
                SubContent2: "รองรับกับการทำงานหลายลักษณะการทำงาน เช่น มีกะเดียวหรือมีหลายกะ มีวันหยุดสัปดาห์ไม่เหมือนกัน สามารถกำหนดการทำงานครบ 2 กะ ใน 1 วัน (Split Shift) และสามารถกำหนดช่วงเวลาครบ 1 คาบของการทำงานได้ โดยไม่จำเป็นต้องกำหนดเป็น 1 สัปดาห์ นอกจากนี้ในกะการทำงานเดียวกันยังสามารถกำหนดให้แต่ละวันมีช่วงเวลาการทำงานที่แตกต่างกันได้อีกด้วย",
                SubContent3: "สามารถอ่านข้อมูลจากเครื่องบันทึกเวลา (เครื่องรูดบัตร) เข้ามาประมวลผลได้ โดยสามารถกำหนด format ของ text file จากเครื่องบันทึกเวลาได้ ทำให้รองรับเครื่องบันทึกเวลาหลายรุ่น หลายยี่ห้อ",
                SubContent4: "ระบบสามารถทำการกำหนดวันหยุดต่าง ๆ ประจำแต่ละปีของบริษัทได้ โดยข้อมูลวันหยุดนี้สามารถที่จะกำหนดให้ใช้ได้ทั้งบริษัทหรือสามารถที่จะกำหนดให้ให้กับเฉพาะบางหน่วยงานหรือบางประเภทพนักงาน",
            },
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Section1.Header}</h5>
                <span>{pageText.Section1.MainContent}</span>
                <br />
                <br />
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Section2.Header}</h5>
                <ul className="isabaya" style={{marginTop: "15px"}}>
                    <li><span>{pageText.Section2.SubContent1}</span></li>
                    <li><span>{pageText.Section2.SubContent2}</span></li>
                    <li><span>{pageText.Section2.SubContent3}</span></li>
                    <li><span>{pageText.Section2.SubContent4}</span></li>
                </ul>
                <br />
                <br />
                <img alt="" className="img-fluid" style={{display: "block", margin: "0 auto"}} src={process.env.PUBLIC_URL + "/image/products0006.jpg"} />
                <br />
                <br />
            </div>
        );
    }
}

export default Products06