import React, { Component } from 'react'

class Products10 extends Component {

    render() {
        let pageText = {
            Header: "ระบบประเมิน (Appraisal Module)",
            MainContent: "ระบบประเมินผลบุคลากรเป็นระบบย่อยของ HRNet ที่สนับสนุนการพิจารณาประสิทธิภาพของบุคลากรในองค์กรเพื่อช่วยในการเลือกเฟ้นบุคลากรที่มีคุณภาพให้เข้ามาปฏิบัติงานในตำแหน่งงานที่เห็นสมควร และเพื่อวางแผนในการพัฒนาความรู้ความสามารถบุคลากรในองค์กรให้เต็มศักยภาพของแต่ละบุคคล นอกจากนี้การประเมินผลดังกล่าวอาจเก็บข้อมูลความพึงพอใจในการทำงานของบุคลากร ตลอดจนทัศนคติของบุคลากร สิ่งเหล่านี้จะช่วยให้องค์กรสามารถหามาตรการที่จะนำมาใช้ในการบริหารทรัพยากรบุคคลที่มีประสิทธิภาพต่อไป ตัวอย่างความสามารถของระบบนี้ ได้แก่",
            SubContent1: "สามารถออกแบบแบบฟอร์มการประเมินผลได้เอง โดยแต่ละแบบประเมินผลสามารถใช้กับบุคลากรแต่ละประเภท หน่วยงาน ตำแหน่ง หรือสถานภาพการบังคับบัญชา เช่น แบบประเมินผลสำหรับหัวหน้าประเมินลูกน้อง แบบประเมินสำหรับพนักงานในหน่วยงาน Profit Center แบบประเมินสำหรับพนักงานทดลองงานแบบประเมินสำหรับพนักงานระดับผู้ช่วยผู้อำนวยการขึ้นไป เป็นต้น",
            SubContent2: "สามารถให้น้ำหนักคะแนนปัจจัยในแต่ละหัวข้อประเมินแตกต่างกันตามความสำคัญของแต่ละหัวข้อ",
            SubContent3: "สามารถใช้ผลการประเมินนี้ในการปรับเงินเดือน ให้โบนัส หรือเลื่อนตำแหน่งได้ โดยสามารถสร้างเป็นสูตรได้",
            SubContent4: "สามารถเชื่อมโยงข้อมูลรายการคุณสมบัติระหว่างพนักงานกับตำแหน่งเพื่อเป็นข้อมูล ใช้ในการพัฒนาบุคลากรต่อไป",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <span>{pageText.MainContent}</span>
                <ul className="isabaya" style={{marginTop: "15px"}}>
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                    <li><span>{pageText.SubContent4}</span></li>
                </ul>
                <br />
                <br />
                <img alt="" className="img-fluid" style={{display: "block", margin: "0 auto"}} src={process.env.PUBLIC_URL + "/image/products0010.jpg"} />
                <br />
                <br />
            </div>
        );
    }
}

export default Products10