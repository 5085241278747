import React, { Component } from 'react'
import BannerWidth10 from '../components/BannerWidth10'
import NavbarProducts from '../components/NavbarProducts'
import Products00 from '../pages/products_detail/Products00'
import Products01 from '../pages/products_detail/Products01'
import Products02 from '../pages/products_detail/Products02'
import Products03 from '../pages/products_detail/Products03'
import Products04 from '../pages/products_detail/Products04'
import Products05 from '../pages/products_detail/Products05'
import Products06 from '../pages/products_detail/Products06'
import Products07 from '../pages/products_detail/Products07'
import Products08 from '../pages/products_detail/Products08'
import Products09 from '../pages/products_detail/Products09'
import Products10 from '../pages/products_detail/Products10'
import Products11 from '../pages/products_detail/Products11'
import Products12 from '../pages/products_detail/Products12'
import Products13 from '../pages/products_detail/Products13'
import Products14 from '../pages/products_detail/Products14'
import Products15 from '../pages/products_detail/Products15'
import Products16 from '../pages/products_detail/Products16'
import Products17 from '../pages/products_detail/Products17'


class Products extends Component {

    render() {
        const pageIndexToDisplay = this.props.PageIndexToDisplay;
        let page;
        if(pageIndexToDisplay === "01") { page = <Products01 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "02") { page = <Products02 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "03") { page = <Products03 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "04") { page = <Products04 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "05") { page = <Products05 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "06") { page = <Products06 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "07") { page = <Products07 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "08") { page = <Products08 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "09") { page = <Products09 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "10") { page = <Products10 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "11") { page = <Products11 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "12") { page = <Products12 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "13") { page = <Products13 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "14") { page = <Products14 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "15") { page = <Products15 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "16") { page = <Products16 lang={this.props.lang} /> }
        else if(pageIndexToDisplay === "17") { page = <Products17 lang={this.props.lang} /> }
        else { page = <Products00 lang={this.props.lang} /> }

        return (
            <div>
                <BannerWidth10 Image={process.env.PUBLIC_URL + "/image/banner_products0001.png"} />
                <div className="row d-md-none" style={{ backgroundColor: "rgb(244,244,244)" }}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10"><NavbarProducts lang={this.props.lang} /></div>
                    <div className="col-sm-1"></div>
                </div>
                <div className="row" style={{ backgroundColor: "rgb(244,244,244)" }}>
                    <div className="col-sm-1 col-md-1"></div>
                    <div className="col-md-3 d-none d-md-block"><NavbarProducts lang={this.props.lang} /></div>
                    <div className="col-sm-10 col-md-7">
                        {page}
                    </div>
                    <div className="col-sm-1 col-md-1"></div>
                </div>
            </div>
        );
    }
}

export default Products