import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class SampleListOfMyProduct extends Component {

    render() {
        let headerText = "";
        let seeMoreText = "";
        let menuText = ["", "", "", "", "", "", "", ""];
        if (this.props.lang === "en-US") {
            headerText = <h5 style={{ padding: "0px 10px 0px 10px", fontSize: "1.18em" }} className="isabaya-font-green">ความสำเร็จ<br />ในการออกแบบและพัฒนาระบบสารสนเทศ</h5>; //"ความสำเร็จ<br />ในการออกแบบและพัฒนาระบบสารสนเทศ";
            seeMoreText = "more info >";
            menuText = 
            [
                "โครงการพัฒนาระบบวิเคราะห์ความเสี่ยงตามหลักธรรมาภิบาลของแผนงาน/โครงการที่ของบประมาณแผ่นดิน", 
                "โครงการพัฒนาระบบจ่ายคืนผู้ฝาก", 
                "โครงการพัฒนาระบบ Data warehouse ข้อมูลอสังหาริมทรัพย์", 
                "โครงการพัฒนาระบบบริหารทรัพยากรบุคคล", 
                "โครงการพัฒนาระบบบริการธุรกรรมโอนเงิน", 
                "โครงการพัฒนาระบบอินเตอร์เน็ทแบงก์กิ้ง", 
                "โครงการพัฒนาระบบโอนเงินพร้อมเพย์", 
                "โครงการพัฒนาระบบทะเบียน PromptPay"
            ];
        }
        else {
            headerText = <h5 style={{ padding: "0px 10px 0px 10px", fontSize: "1.18em" }} className="isabaya-font-green">ความสำเร็จ<br />ในการออกแบบและพัฒนาระบบสารสนเทศ</h5>; //"ความสำเร็จ<br />ในการออกแบบและพัฒนาระบบสารสนเทศ";
            seeMoreText = "ดูเพิ่มเติม >";
            menuText = 
            [
                "โครงการพัฒนาระบบวิเคราะห์ความเสี่ยงตามหลักธรรมาภิบาลของแผนงาน/โครงการที่ของบประมาณแผ่นดิน", 
                "โครงการพัฒนาระบบจ่ายคืนผู้ฝาก", 
                "โครงการพัฒนาระบบ Data warehouse ข้อมูลอสังหาริมทรัพย์", 
                "โครงการพัฒนาระบบบริหารทรัพยากรบุคคล", 
                "โครงการพัฒนาระบบบริการธุรกรรมโอนเงิน", 
                "โครงการพัฒนาระบบอินเตอร์เน็ทแบงก์กิ้ง", 
                "โครงการพัฒนาระบบโอนเงินพร้อมเพย์", 
                "โครงการพัฒนาระบบทะเบียน PromptPay"
            ];
        }


        return (
            <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                <br /><br />
                {headerText}
                <ul className="isabaya-simple-menu" style={{ fontSize: "15px" }}>
                    <li><Link to="/works/work01">{menuText[0]}</Link></li>
                    <li><Link to="/works/work02">{menuText[1]}</Link></li>
                    <li><Link to="/works/work03">{menuText[2]}</Link></li>
                    <li><Link to="/works/work04">{menuText[3]}</Link></li>
                    <li><Link to="/works/work05">{menuText[4]}</Link></li>
                    <li><Link to="/works/work06">{menuText[5]}</Link></li>
                    <li><Link to="/works/work07">{menuText[6]}</Link></li>
                    <li><Link to="/works/work08">{menuText[7]}</Link></li>
                </ul>
                <div style={{ textAlign: "right", fontSize: "12px" }}>
                    <Link to="/works" className="isabaya-font-green-static">{seeMoreText}</Link>
                </div>
            </div>
        );
    }
}

export default SampleListOfMyProduct