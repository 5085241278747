import React, { Component } from 'react'

class Products01 extends Component {

    render() {
        let pageText = {
            Header: "ระบบโครงสร้างองค์กร (Organization Structure Module)",
            MainContent: "",
            SubContent1: "เป็นระบบจัดเก็บข้อมูลโครงสร้างองค์กร ซึ่งจะบอกถึงรายละเอียดต่างๆ ขององค์กรตัวอย่างความสามารถของระบบนี้ ได้แก่จัดเก็บข้อมูลหลักขององค์กรเป็น Hierarchy ได้ไม่จำกัดจำนวนชั้น ข้อมูลหลักเหล่านี้ได้แก่",
            SubContent1_1: "หน่วยธุรกิจ",
            SubContent1_2: "ประเภทบุคลากร (Personnel Classification)",
            SubContent1_3: "ภารกิจ (Task Catalog)",
            SubContent1_4: "ประเภทงาน (Job Catalog)",
            SubContent1_5: "ความสามารถ หรือ คุณสมบัติ/ความสามารถของบุคลากร (Qualification/Competency Catalog)",
            SubContent2: "รองรับการวางแผนอัตรากำลังในหน่วยงาน โดยผู้ใช้กำหนดเป็นตำแหน่ง (อัตรา) ของประเภทงานนั้นในแต่ละหน่วยงานเพื่อรองรับพนักงาน ผู้ใช้สามารถดูอัตราที่ว่างในแต่ละหน่วยงาน การบรรจุพนักงานในหน่วยงานจะบรรจุตามอัตราที่ว่างอยู่ สามารถรองรับการลดหรือเพิ่มอัตราในหน่วยงาน ระบบจะต้องเก็บรายละเอียดของแต่ละอัตราตำแหน่งเช่น ชื่ออัตรา รหัสอัตรา ประเภทงาน วันเริ่มต้นใช้ และวันสิ้นสุด เป็นต้น",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <ul className="isabaya">
                    <li>
                        <span>{pageText.SubContent1}</span>
                        <ol>
                            <li><span>{pageText.SubContent1_1}</span></li>
                            <li><span>{pageText.SubContent1_2}</span></li>
                            <li><span>{pageText.SubContent1_3}</span></li>
                            <li><span>{pageText.SubContent1_4}</span></li>
                            <li><span>{pageText.SubContent1_5}</span></li>
                        </ol>
                    </li>
                    <li><span>{pageText.SubContent2}</span></li>
                </ul>
                <br />
                <br />
                <img alt="" className="img-fluid" style={{display: "block", margin: "0 auto"}} src={process.env.PUBLIC_URL + "/image/products0001.jpg"} />
            </div>
        );
    }
}

export default Products01