import React, { Component } from 'react'

class Products09 extends Component {

    render() {
        let pageText = {
            Header: "ระบบพัฒนาบุคลากร (Personnel Development Module)",
            MainContent: "เป็นระบบย่อยของ HRNet ที่คอยควบคุมการดำเนินงานส่งเสริมและพัฒนาบุคลากรในองค์กรให้มีความสามารถ มีทักษะในการทำงานและมีทัศนคติที่ดีในการทำงาน ซึ่งจะส่งผลให้พนักงานสามารถปฏิบัติงานได้อย่างมีประสิทธิภาพ และเพิ่มขวัญและกำลังใจในการทำงาน ตัวอย่างความสามารถของระบบนี้ได้แก่",
            SubContent1: "สามารถเปิดแผนการฝึกอบรมที่สร้างไว้ เพื่อเป็นหลักสูตรการอบรมที่จะนำมาใช้จริงได้ ซึ่งจะมีรายละเอียดเพิ่มเติมจากแผนการฝึกอบรม เช่น สามารถกำหนดวันที่เริ่มเรียน, วันที่สิ้นสุดการเรียน, วันที่เริ่มเปิดการลงทะเบียนเรียน, วันที่สิ้นสุดการลงทะเบียนเรียน, ชื่อหลักสูตรเป็นภาษาไทย-อังกฤษ ฯลฯ นอกจากนี้ยังสามารถกำหนดตอนเรียนของรายวิชาสำหรับหลักสูตรที่เปิดใช้จริงได้ และสามารถดูรายชื่อของบุคลากรที่เข้าเรียนในหลักสูตรที่เปิดใช้นี้ได้",
            SubContent2: "สามารถนำรายวิชาที่ได้กรอกข้อมูลเข้าไปนั้น นำมาเปิดเป็นตอนเรียน (หรือชั้นเรียน) ที่ใช้ในการสอนจริง รวมถึงการเก็บข้อมูลเพิ่มเติมจากข้อมูลรายวิชา เช่น วันที่เริ่มสอน, วันที่สิ้นสุดการสอน, ห้องหรือสถานที่ที่ใช้ในการเรียน การสอน, จำนวนชั่วโมงเรียน, ค่าใช้จ่าย และวิทยากรของแต่ละรายวิชาที่เปิด เป็นต้น นอกจากนี้ยังสามารถดูรายชื่อของบุคลากรที่เข้ารับการอบรมในชั้นเรียนที่เปิดได้ด้วย",
            SubContent3: "แสดงรายละเอียดที่เกี่ยวข้องกับค่าใช้จ่ายในการฝึกอบรม เช่น งบประมาณการฝึกอบรม ตามปีงบประมาณหรือตามข้อกำหนดขององค์กร และสามารถแยกค่าใช้จ่ายในการฝึกอบรมตามหน่วยงานขององค์กร หรือค่าใช้จ่ายในการฝึกอบรมตามรายบุคคล",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <span>{pageText.MainContent}</span>
                <ul className="isabaya" style={{marginTop: "15px"}}>
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                </ul>
                <br />
                <br />
                <img alt="" className="img-fluid" style={{display: "block", margin: "0 auto"}} src={process.env.PUBLIC_URL + "/image/products0009.jpg"} />
            </div>
        );
    }
}

export default Products09