import React, { Component } from 'react'

class ContactWithEmail extends Component {
  
  constructor(props) {
    super(props);
    this.state = { FromName: '', FromEmail: '', Subject: '', Message: '',  SendDisable: true };
  }

  FromNameChangeHandler = (event) => {
    this.setState({FromName: event.target.value}, function () {
      if(this.state.FromName && this.state.FromEmail && this.state.Subject && this.state.Message) {
        this.setState({SendDisable: false});
      }
      else {
        this.setState({SendDisable: true});
      }
    });
  }

  FromEmailChangeHandler = (event) => {
    this.setState({FromEmail: event.target.value}, function () {
      if(this.state.FromName && this.state.FromEmail && this.state.Subject && this.state.Message) {
        this.setState({SendDisable: false});
      }
      else {
        this.setState({SendDisable: true});
      }
    });
  }

  SubjectChangeHandler = (event) => {
    this.setState({Subject: event.target.value}, function () {
      if(this.state.FromName && this.state.FromEmail && this.state.Subject && this.state.Message) {
        this.setState({SendDisable: false});
      }
      else {
        this.setState({SendDisable: true});
      }
    });
  }

  MessageChangeHandler = (event) => {
    this.setState({Message: event.target.value}, function () {
      if(this.state.FromName && this.state.FromEmail && this.state.Subject && this.state.Message) {
        this.setState({SendDisable: false});
      }
      else {
        this.setState({SendDisable: true});
      }
    });
  }

  SendEmailHandler = (event) => {
    this.setState({SendDisable: true});
    fetch(process.env.PUBLIC_URL + "/api/email/sendmail.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state)
    })
    .then(response => response.json())
    .then(data => {
      if(data.Success) {
        this.setState({FromName: '', FromEmail:'', Subject:'', Message:'', SendDisable:true});
        window.Notify(data.Description, 'success');
      }
      else {
        window.Notify(data.ResponseCode + ": " + data.Description, 'danger');
        this.setState({SendDisable: false});
        console.log(data);
      }
    });
  }

  render() {
    let nameText = "";
    let emailText = "";
    let subjectText = "";
    let messageText = "";
    let sendButtonTetx = "";
    if(this.props.lang === "en-US") {
      nameText = "Name";
      emailText = "E-mail";
      subjectText = "Subject";
      messageText = "Message";
      sendButtonTetx = "Send email";
    }
    else {
      nameText = "ชื่อ";
      emailText = "อีเมล";
      subjectText = "หัวข้อ";
      messageText = "ข้อความ";
      sendButtonTetx = "ส่งอีเมล";
    }

    return (
      <div className="p-4 ml-sm-auto" style={{backgroundColor: "rgb(226,226,226)", marginTop: "30px", maxWidth: "400px"}}>
        <div className="form-group">
          <label className="isabaya-font-green">{nameText}</label>
          <input id="textfield" name="textfield" type="text" className="form-control isabaya-border-gray" maxLength="40" onChange={this.FromNameChangeHandler} value={this.state.FromName} />
        </div>
        <div className="form-group">
          <label className="isabaya-font-green">{emailText}</label>
          <input id="textfield2" name="textfield2" type="text" maxLength="40" className="form-control isabaya-border-gray" onChange={this.FromEmailChangeHandler} value={this.state.FromEmail} />
        </div>
        <div className="form-group">
          <label className="isabaya-font-green">{subjectText}</label>
          <input id="textfield3" name="textfield3" type="text" maxLength="40" className="form-control isabaya-border-gray" onChange={this.SubjectChangeHandler} value={this.state.Subject} />
        </div>
        <div className="form-group">
          <label className="isabaya-font-green">{messageText}</label>
          <textarea id="textfield4" name="textfield4" rows="6" maxLength="200" className="form-control isabaya-border-gray" style={{resize: "none"}} onChange={this.MessageChangeHandler} value={this.state.Message}></textarea>
        </div>
        <input type='button' value={sendButtonTetx} disabled={this.state.SendDisable} onClick={this.SendEmailHandler} />
      </div>
    );
  }
}

export default ContactWithEmail