import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Header from './components/Header'
import Home from './components/Home'
import AboutUs from './components/AboutUs'
import Services from './components/Services'
import Products from './components/Products'
import Works from './components/Works'


const Notfound = () => <h1>Not found</h1>
const app_lang = {
  TH: 'th-TH',
  EN: 'en-US'
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { lang: app_lang.TH }
    this.handleAppLangChanged = this.handleAppLangChanged.bind(this);
  }

  handleAppLangChanged(e) {
    e.preventDefault();
    this.setState({ lang: e.target.lang }, function () {
      if(this.state.lang === "en-US"){
        window.SetCookie("lang", "en", null, "/", null, null);
      }
      else{
        window.SetCookie("lang", "th", null, "/", null, null);
      }
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <Header handleAppLangChanged={this.handleAppLangChanged} lang={this.state.lang} />
        <Switch>
          <Route exact path="/" render={() => <Home lang={this.state.lang} />} />
          <Route path="/aboutus" render={() => <AboutUs lang={this.state.lang} />} />
          <Route path="/services" render={() => <Services lang={this.state.lang} />} />
          <Route exact path="/products" render={() => <Products lang={this.state.lang} PageIndexToDisplay="00" />} />
          <Route path="/products/producthrnet01" render={() => <Products lang={this.state.lang} PageIndexToDisplay="01" />} />
          <Route path="/products/producthrnet02" render={() => <Products lang={this.state.lang} PageIndexToDisplay="02" />} />
          <Route path="/products/producthrnet03" render={() => <Products lang={this.state.lang} PageIndexToDisplay="03" />} />
          <Route path="/products/producthrnet04" render={() => <Products lang={this.state.lang} PageIndexToDisplay="04" />} />
          <Route path="/products/producthrnet05" render={() => <Products lang={this.state.lang} PageIndexToDisplay="05" />} />
          <Route path="/products/producthrnet06" render={() => <Products lang={this.state.lang} PageIndexToDisplay="06" />} />
          <Route path="/products/producthrnet07" render={() => <Products lang={this.state.lang} PageIndexToDisplay="07" />} />
          <Route path="/products/producthrnet08" render={() => <Products lang={this.state.lang} PageIndexToDisplay="08" />} />
          <Route path="/products/producthrnet09" render={() => <Products lang={this.state.lang} PageIndexToDisplay="09" />} />
          <Route path="/products/producthrnet10" render={() => <Products lang={this.state.lang} PageIndexToDisplay="10" />} />
          <Route path="/products/producthrnet11" render={() => <Products lang={this.state.lang} PageIndexToDisplay="11" />} />
          <Route path="/products/producthrnet12" render={() => <Products lang={this.state.lang} PageIndexToDisplay="12" />} />
          <Route path="/products/producthrnet13" render={() => <Products lang={this.state.lang} PageIndexToDisplay="13" />} />
          <Route path="/products/producthrnet14" render={() => <Products lang={this.state.lang} PageIndexToDisplay="14" />} />
          <Route path="/products/producthrnet15" render={() => <Products lang={this.state.lang} PageIndexToDisplay="15" />} />
          <Route path="/products/productimsabaya16" render={() => <Products lang={this.state.lang} PageIndexToDisplay="16" />} />
          <Route path="/products/productimsabaya17" render={() => <Products lang={this.state.lang} PageIndexToDisplay="17" />} />
          <Route exact path="/works" render={() => <Works lang={this.state.lang} PageIndexToDisplay="00" />} />
          <Route path="/works/work01" render={() => <Works lang={this.state.lang} PageIndexToDisplay="01" />} />
          <Route path="/works/work02" render={() => <Works lang={this.state.lang} PageIndexToDisplay="02" />} />
          <Route path="/works/work03" render={() => <Works lang={this.state.lang} PageIndexToDisplay="03" />} />
          <Route path="/works/work04" render={() => <Works lang={this.state.lang} PageIndexToDisplay="04" />} />
          <Route path="/works/work05" render={() => <Works lang={this.state.lang} PageIndexToDisplay="05" />} />
          <Route path="/works/work06" render={() => <Works lang={this.state.lang} PageIndexToDisplay="06" />} />
          <Route path="/works/work07" render={() => <Works lang={this.state.lang} PageIndexToDisplay="07" />} />
          <Route path="/works/work08" render={() => <Works lang={this.state.lang} PageIndexToDisplay="08" />} />

          <Route component={Notfound} />
        </Switch>
      </div>
    )
  }
}

export default App
