import React, { Component } from 'react'
import ContentBox from '../components/ContentBox'
import SampleListOfMyProduct from '../components/SampleListOfMyProduct'
import ContactWithEmail from '../components/ContactWithEmail'
import BannerWidth12 from '../components/BannerWidth12'

class Home extends Component {

    render() {
        let clientTitle = "";
        let contactTitle = "";
        let contentBox_1 = { Title: "", Content: "" };
        let contentBox_2 = { Title: "", Content: "" };
        let isabayaAddress = { Line1: "", Line2: "", Line3: "" };
        if (this.props.lang === "en-US") {
            clientTitle = "Clients";
            contactTitle = "Contact";
            contentBox_1 = { Title: "เชื่อมั่น เชี่ยวชาญ", Content: "บริษัท ไอสัปปายะ จำกัด เชื่อมั่นว่าจะสามารถพัฒนาระบบสารสนเทศให้มีความเชื่อถือได้สูง (High Reliability) และมีสมรรถนะสูง (High performance) ตรงกับความต้องการของลูกค้า โดยอาศัยบุคลากรที่มีฝีมือ มีความรู้ความเชี่ยวชาญ มีประสบการณ์ และมีความรับผิดชอบ หลักการของเราคือซอฟท์แวร์ที่พัฒนาจะดีได้ ต้องมีแบบที่ดี เราจึงมุ่งเน้นการออกแบบซอฟท์แวร์ในส่วนที่ซับซ้อนให้ชัดเจนที่สุด และตรงกับความต้องการที่วิเคราะห์อย่างถี่ถ้วนและรอบคอบ" };
            contentBox_2 = { Title: "บุคลากรของเรา", Content: "เรามีบุคลากรระดับปริญญาเอกทางด้านคอมพิวเตอร์จากมหาวิทยาลัยชั้นนำในประเทศสหรัฐอเมริกา (โดยไม่ต้องเรียนระดับปริญญาโทก่อน) รับหน้าที่วิเคราะห์ระบบและเป็นสถาปนิกออกแบบระบบสารสนเทศและฐานข้อมูล บุคลากรท่านนี้เคยเป็นอาจารย์สอนวิชาด้านคอมพิวเตอร์และสารสนเทศหลักสูตรปริญญาโทและเอกในสถาบันการศึกษาระดับสูงของประเทศไทยมาแล้วกว่า 25 ปี" };
            isabayaAddress = {
                Line1: "iSabaya Co.,Ltd.",
                Line2: "2/37 Pahonyotin 40, Senanikom, Jatuchak, Bangkok 10900, Thailand",
                Line3: "Tel. 02-940-1021-2 Fax. 02-940-0940"
            };
        }
        else {
            clientTitle = "ลูกค้าของเรา";
            contactTitle = "ติดต่อเรา"
            contentBox_1 = { Title: "เชื่อมั่น เชี่ยวชาญ", Content: "บริษัท ไอสัปปายะ จำกัด เชื่อมั่นว่าจะสามารถพัฒนาระบบสารสนเทศให้มีความเชื่อถือได้สูง (High Reliability) และมีสมรรถนะสูง (High performance) ตรงกับความต้องการของลูกค้า โดยอาศัยบุคลากรที่มีฝีมือ มีความรู้ความเชี่ยวชาญ มีประสบการณ์ และมีความรับผิดชอบ หลักการของเราคือซอฟท์แวร์ที่พัฒนาจะดีได้ ต้องมีแบบที่ดี เราจึงมุ่งเน้นการออกแบบซอฟท์แวร์ในส่วนที่ซับซ้อนให้ชัดเจนที่สุด และตรงกับความต้องการที่วิเคราะห์อย่างถี่ถ้วนและรอบคอบ" };
            contentBox_2 = { Title: "บุคลากรของเรา", Content: "เรามีบุคลากรระดับปริญญาเอกทางด้านคอมพิวเตอร์จากมหาวิทยาลัยชั้นนำในประเทศสหรัฐอเมริกา (โดยไม่ต้องเรียนระดับปริญญาโทก่อน) รับหน้าที่วิเคราะห์ระบบและเป็นสถาปนิกออกแบบระบบสารสนเทศและฐานข้อมูล บุคลากรท่านนี้เคยเป็นอาจารย์สอนวิชาด้านคอมพิวเตอร์และสารสนเทศหลักสูตรปริญญาโทและเอกในสถาบันการศึกษาระดับสูงของประเทศไทยมาแล้วกว่า 25 ปี" };
            isabayaAddress = {
                Line1: "บริษัท ไอสัปปายะ จำกัด",
                Line2: "2/37 ซอยพหลโยธิน 40 ถนนพหลโยธิน แขวงเสนานิคม เขตจตุจักร กทม. 10900",
                Line3: "โทร. 02-940-1021-2 แฟกซ์. 02-940-0940"
            };
        }

        return (
            <div>
                <BannerWidth12 Image={process.env.PUBLIC_URL} />
                <div className="row" style={{ backgroundColor: "rgb(244, 244, 244)" }}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <div className="row">
                            <div className="col-sm-4"><ContentBox ImgSrc={process.env.PUBLIC_URL + "/image/home_pic0001.png"} Title={contentBox_1.Title} Content={contentBox_1.Content} /></div>
                            <div className="col-sm-4"><ContentBox ImgSrc={process.env.PUBLIC_URL + "/image/home_pic0002.png"} Title={contentBox_2.Title} Content={contentBox_2.Content} /></div>
                            <div className="col-sm-4"><SampleListOfMyProduct lang={this.props.lang} /></div>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div>
                <div className="row justify-content-center">
                    <div className="container">
                        <h5 style={{ padding: "0px 10px 0px 10px", margin: "30px 0px 20px 0px" }} className="isabaya-font-green">{clientTitle}</h5>
                        <img alt="" style={{ width: "195px", margin: "10px 10px 10px 10px" }} src={process.env.PUBLIC_URL + "/image/client0001.png"} />
                        <img alt="" style={{ width: "195px", margin: "10px 10px 10px 10px" }} src={process.env.PUBLIC_URL + "/image/client0002.png"} />
                        <img alt="" style={{ width: "195px", margin: "10px 10px 10px 10px" }} src={process.env.PUBLIC_URL + "/image/client0003.png"} />
                        <img alt="" style={{ width: "195px", margin: "10px 10px 10px 10px" }} src={process.env.PUBLIC_URL + "/image/client0004.png"} />
                        <img alt="" style={{ width: "195px", margin: "10px 10px 10px 10px" }} src={process.env.PUBLIC_URL + "/image/client0005.png"} />
                        <img alt="" style={{ width: "195px", margin: "10px 10px 10px 10px" }} src={process.env.PUBLIC_URL + "/image/client0006.png"} />
                        <img alt="" style={{ width: "195px", margin: "10px 10px 10px 10px" }} src={process.env.PUBLIC_URL + "/image/client0007.png"} />
                        <img alt="" style={{ width: "195px", margin: "10px 10px 10px 10px" }} src={process.env.PUBLIC_URL + "/image/client0008.png"} />
                        <img alt="" style={{ width: "195px", margin: "10px 10px 10px 10px" }} src={process.env.PUBLIC_URL + "/image/client0009.png"} />
                        <img alt="" style={{ width: "195px", margin: "10px 10px 10px 10px" }} src={process.env.PUBLIC_URL + "/image/client0010.png"} />
                        <img alt="" style={{ width: "195px", margin: "10px 10px 10px 10px" }} src={process.env.PUBLIC_URL + "/image/client0011.png"} />
                    </div>
                </div>
                <div className="row" style={{ backgroundColor: "rgb(244, 244, 244)" }}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-5">
                        <h5 style={{ padding: "0px 10px 0px 10px", margin: "30px 0px 20px 0px" }} className="isabaya-font-green">{contactTitle}</h5>
                        <p style={{ padding: "0px 0px 0px 20px", fontSize: "14px" }}>
                            {isabayaAddress.Line1}<br />
                            {isabayaAddress.Line2}<br />
                            {isabayaAddress.Line3}<br />
                            <br />
                            <a href="https://www.facebook.com/iSabayaCompany/" target="_blank" rel="noopener noreferrer" className="text-dark" style={{ textDecoration: "none" }}><img alt="" style={{ width: "32px", margin: "0px 10px 0px 0px" }} src={process.env.PUBLIC_URL + "/image/facebook-icon.png"} />{isabayaAddress.Line1}</a>
                        </p>
                    </div>
                    <div className="col-sm-5">
                        <ContactWithEmail lang={this.props.lang} />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-sm-1"></div>
                </div>
            </div>
        );
    }
}

export default Home