import React, { Component } from 'react'

class Products00 extends Component {

    render() {
        let pageText = {
            HRNet: {
                Header: "HRNet",
                Paragraph1: "HRNet เป็นระบบบริหารทรัพยากรบุคคลสำหรับองค์กรขนาดใหญ่ ได้รับการออกแบบตั้งแต่เริ่มแรกให้มีความยืดหยุ่นสูง เพื่อให้รองรับการปรับเปลี่ยนกฎหมาย นโยบาย และระเบียบได้มากที่สุดโดยไม่ต้องแก้ไขโปรแกรมใน HRNet นโยบาย กฎเกณฑ์ และระเบียบบริหารทรัพยากรบุคคลถูกแยกออกมาเป็นข้อมูล Business rules ไม่ได้เขียนฝังอยู่ในตัวโปรแกรม เมื่อกฎหมาย นโยบาย และระเบียบเปลี่ยน ผู้ใช้ที่ได้รับอนุญาตสามารถแก้ไขเพิ่มเติม Business rules ให้สอดคล้องกับการเปลี่ยนแปลงได้เอง และทดสอบเฉพาะ Business rules ที่แก้ไขเพิ่มเติมซึ่งใช้เวลาไม่นาน ทำให้ HRNet สามารถสนองตอบการเปลี่ยนแปลงได้อย่างรวดเร็วทันใจผู้บริหาร",
                Paragraph2: "HRNet ยังมีสารสนเทศสำหรับผู้บริหาร ( Internet - based human resource information for management) เพื่อรองรับการใช้สารสนเทศทรัพยากรบุคคลในการตัดสินใจผู้บริหารระดับสูงเพื่อกำหนดยุทธศาสตร์และนโยบายในการดำเนินธุรกิจขององค์กร การใช้สารสนเทศดังกล่าวถูกออกแบบให้ใช้งานง่ายสำหรับผู้ใช้ระดับบริหารแต่ในขณะเดียวกันก็มีความยืดหยุ่นให้ผู้บริหารสามารถเปลี่ยนรูปแบบสารสนเทศที่ต้องการดูได้เอง",
            },
            IMSabaya: {
                Header: "IM Sabaya",
                Paragraph1: "imSabaya คือระบบบริหารจัดการลงทุนแบบบูรณาการ (Integrated Investment Management System) ซึ่งได้รับการออกแบบและพัฒนาโดย บริษัท ไอสัปปายะ จำกัด มีคุณสมบัติโดยรวมปัจจุบัน imSabaya มีระบบย่อย 2 ระบบได้แก่ ระบบทะเบียนกองทุนรวม (Mutual Fund Registry System) และระบบทะเบียนกองทุนสำรองเลี้ยงชีพ (Mutual Fund Registry System) บริษัท มีแผนที่จะพัฒนาระบบย่อยอื่นต่อไป เช่น ระบบบัญชีกองทุน เป็นต้น",
                Paragraph2: "บูรณาการข้อมูลลูกค้าที่ลงทุนด้วยเครื่องมือการลงทุน (Investment Instruments) ต่าง ๆ ไว้ที่เดียวกัน รองรับการทำCRM (Customer Relationship Management)",
                Paragraph3: "สามารถตรวจสอบการลงทุนตามที่ กลต. ปปง. และ หน่วยงานอื่น ๆ ของรัฐกำหนด",
                Paragraph4: "กฏเกณฑ์ธุรกิจ (Business Rules) ที่มีโอกาสถูกเปลี่ยนบ่อยครั้ง เช่น เกณฑ์ที่กำหนดโดย กลต. เป็นต้น จะถูกออกแบบให้แยกออกจากส่วนอื่น ๆ ของระบบ การแก้ไขปรับปรุงกฎเกณฑ์ธุรกิจที่มีอยู่แล้ว หรือแม้กระทั่งเพิ่มกฎเกณฑ์ธุรกิจใหม่ สามารถทำได้โดยไม่กระทบกฎเกณฑ์ธุรกิจอื่น ๆ หรือส่วนอื่นของระบบ ช่วยลดกำลังคน และเวลาในการทำ Regression Test",
                Paragraph5: "สนับสนุนให้มีการตรวจสอบ ตรวจทาน ธุรกรรมการลงทุน (Investment Transactions) ทั้งโดยพนักงานกันเอง และโดยระบบ เพื่อป้องกันความผิดพลาด ก่อนการประมวลผล",
                Paragraph6: "หากธุรกรรมที่ประมวลผลสำเร็จแล้ว เกิดการผิดพลาดขึ้น พนักงานผู้ได้รับสิทธิ สามารถทำธุรกรรมยกเลิกธุรกรรมที่ผิดพลาดด้วยตนเอง ธุรกรรมยกเลิกที่บันทึกไว้ จะมีผลไปยกเลิกธุรกรรมเป้าหมายได้ ต่อเมื่อผ่านการตรวจสอบโดยระบบแล้วเท่านั้น ซึ่งช่วยให้สามารถตรวจสอบการทำธุรกรรมผิดพลาด และการยกเลิกได้ตามมาตรฐานสากล และที่สำคัญคือไม่อนุญาตให้เข้าไปลบและแก้ไขข้อมูลในฐานข้อมูลโดยตรง เพื่อแก้ผลกระทบของธุรกรรมที่ผิดพลาด เนื่องจากเป็นการเปิดช่องทางให้เกิดการทุจริตได้ ",
            }
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green">{pageText.HRNet.Header}</h5>
                <span>{pageText.HRNet.Paragraph1}</span>
                <br />
                <br />
                <span>{pageText.HRNet.Paragraph2}</span>
                <br />
                <br />
                <br />
                <br />
                <br />
                <h5 className="isabaya-font-green">{pageText.IMSabaya.Header}</h5>
                <span>{pageText.IMSabaya.Paragraph1}</span>
                <br />
                <br />
                <span>{pageText.IMSabaya.Paragraph2}</span>
                <br />
                <br />
                <span>{pageText.IMSabaya.Paragraph3}</span>
                <br />
                <br />
                <span>{pageText.IMSabaya.Paragraph4}</span>
                <br />
                <br />
                <span>{pageText.IMSabaya.Paragraph5}</span>
                <br />
                <br />
                <span>{pageText.IMSabaya.Paragraph6}</span>
                <br />
                <br />
            </div>
        );
    }
}

export default Products00