import React, { Component } from 'react'

class Works04 extends Component {

    render() {
        let pageText = {
            Header1: "โครงการพัฒนาระบบบริหารทรัพยากรบุคคล",
            Header2: "ธนาคารอาคารสงเคราะห์",
            MainContent: "",
            SubContent1: "ระบบสรรหาบุคลากร",
            SubContent2: "ระบบจัดการโครงสร้างองค์กร",
            SubContent3: "ระบบทะเบียนประวัติบุคคล",
            SubContent4: "ระบบสวัสดิการ",
            SubContent5: "ระบบการลา",
            SubContent6: "ระบบประมวลผลเงินค่าตอบแทน (Remuneration)",
            SubContent7: "ระบบพัฒนาบุคลากร",
            SubContent8: "ระบบประเมินผล",
            SubContent9: "ระบบประมวลเวลาทำงาน",
        };


        return (
            <div className="pl-md-5 pt-5" style={{width:"100%", height:"100%", backgroundColor: "white"}}>
                <h5 className="isabaya-font-green">{pageText.Header1}</h5>
                <h6 className="isabaya-font-green">{pageText.Header2}</h6>
                <br />
                <ul className="isabaya">
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                    <li><span>{pageText.SubContent4}</span></li>
                    <li><span>{pageText.SubContent5}</span></li>
                    <li><span>{pageText.SubContent6}</span></li>
                    <li><span>{pageText.SubContent7}</span></li>
                    <li><span>{pageText.SubContent8}</span></li>
                    <li><span>{pageText.SubContent9}</span></li>
                </ul>
                <br />
                <br />
            </div>
        );
    }
}

export default Works04