import React, { Component } from 'react'

class Products03 extends Component {

    render() {
        let pageText = {
            Header: "ระบบสรรหาบุคลากร (Recruitment Module)",
            MainContent: "ระบบย่อยของ HRNet ระบบนี้สนับสนุนการวางแผนความต้องการในหน่วยงานต่าง ๆ โดยพิจารณาจากแผนอัตรากำลังของหน่วยงานและจำนวนบุคลากรที่ลาออกหรือเกษียณ เพื่อสรุปตำแหน่งที่หน่วยงานต้องการเพิ่ม การสรรหาบุคลากรเพื่อบรรจุในอัตราที่ว่างทำได้ทั้งการสรรหาจากบุคลากรภายนอก หรือ จากภายในองค์กร ตัวอย่างความสามารถของระบบนี้ได้แก่",
            SubContent1: "สามารถกำหนดข้อมูลทั่วไปของระบบ เช่น ขั้นตอนการติดต่อกับผู้สมัคร สถานะของผู้สมัครให้เหมาะสมกับองค์กรได้",
            SubContent2: "สามารถเก็บข้อมูลต่าง ๆ ของผู้สมัคร เช่น ข้อมูลส่วนตัว ประสบการณ์ และกิจกรรมระหว่างศึกษา ตำแหน่งที่สมัคร การศึกษา เงินเดือนที่คาดหวัง และความสามารถ คุณสมบัติพิเศษ เพื่อใช้เปรียบเทียบความเหมาะสมกับตำแหน่งได้",
            SubContent3: "ระบบช่วยคัดเลือกผู้สมัครโดยผู้ใช้ระบบสามารถเลือกดูผู้สมัครตามมุมมองต่าง ๆ เช่น วุฒิการศึกษา ความสามารถพิเศษ ประสบการณ์ เงินเดือนที่คาดหวัง เป็นต้น",
            SubContent4: "ระบบช่วยคัดเลือกผู้สมัครโดยการเปรียบเทียบคุณสมบัติที่ต้องการของอัตราที่ว่างอยู่กับคุณสมบัติของผู้สมัคร (ตามที่แจ้งหรือตามผลการทดสอบขององค์กร)",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <span>{pageText.MainContent}</span>
                <ul className="isabaya" style={{marginTop: "15px"}}>
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                    <li><span>{pageText.SubContent4}</span></li>
                </ul>
                <br />
                <br />
                <img alt="" className="img-fluid" style={{display: "block", margin: "0 auto"}} src={process.env.PUBLIC_URL + "/image/products0003.jpg"} />
            </div>
        );
    }
}

export default Products03