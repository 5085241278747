import React, { Component } from 'react'

class Products07 extends Component {

    render() {
        let pageText = {
            Header: "ระบบบริหารเวลาปฏิบัติงาน Web application (Time Administration Module)",
            MainContent: "",
            SubContent1: "สามารถกำหนดข้อมูลต่างๆ เช่น ประเภทการลา ปฏิทินวันหยุดประจำ ผู้รับผิดชอบในการบันทึกข้อมูลการมาปฏิบัติงานและการลา พนักงานประจำด่าน-กลุ่มงาน เป็นต้น",
            SubContent2: "สามารถบันทึกข้อมูลการมาปฏิบัติงาน หรือเชื่อม/โอนข้อมูลจากเครื่องบันทึกเวลาการเข้า-ออก",
            SubContent3: "สามารถวางแผนกะ เวลาการปฏิบัติงาน เวลาพัก ในแต่ละวัน/สัปดาห์/เดือนของแต่ละกลุ่ม ล่วงหน้าได้ และสามารถแก้ไขปรับเปลี่ยนตารางกะได้หลากหลาย",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <ul className="isabaya">
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                </ul>
                <br />
                <br />
                <img alt="" className="img-fluid" style={{display: "block", margin: "0 auto"}} src={process.env.PUBLIC_URL + "/image/products0007.jpg"} />
            </div>
        );
    }
}

export default Products07