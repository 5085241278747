import React, { Component } from 'react'

class Products16 extends Component {

    render() {
        let pageText = {
            Header: "ระบบทะเบียนกองทุนรวม",
            MainContent: "",
            SubContent1: "สามารถประมวลผลธุรกรรมครบวงจร เช่น",
            SubContent1_1: "ธุรกรรมซื้อ IPO และ ธุรกรรมจัดสรร IPO (ได้หลายนโยบาย)",
            SubContent1_2: "ธุรกรรมซื้อหน่วยลงทุน และธุรกรรมขายหน่วยลงทุน",
            SubContent1_3: "ธุรกรรมโอนหน่วยลงทุนระหว่างบัญชี",
            SubContent1_4: "ธุรกรรมสับเปลี่ยนหน่วยลงทุนระหว่างกองทุนภายในบริษัทฯ",
            SubContent1_5: "ธุรกรรมโอนการลงทุนเข้ามาจาก หรือออกไปยัง กองทุนภายนอก",
            SubContent1_6: "ธุรกรรมเกี่ยวกับใบรับรองหน่วยลงทุน",
            SubContent1_7: "ธุรกรรมปิดกองทุน",
            SubContent1_8: "ฯลฯ",
            SubContent2: "รองรับธุรกรรมซื้อ ขาย และสับเปลี่ยนหน่วยลงทุน (ระหว่างกองทุนภายใน หรือกับกองทุนภายนอก) แบบ FIFO (First In First Out)",
            SubContent3: "แต่ละกองทุนสามารถมีปฏิทินกำหนดเวลาทำธุรกรรมได้หลายปฏิทิน ตามประเภทธุรกรรม (ซื้อ ขาย สับเปลี่ยน) ช่องทางธุรกรรม และวิธีการชำระเงิน",
            SubContent4: "บัญชีลงทุน (Investment Portfolio) แต่ละบัญชีมีเจ้าของได้มากกว่าหนึ่งราย และสามารถลงทุนในกองทุนรวมได้มากกว่าหนึ่งกอง",
            SubContent5: "สามารถสร้างรายการชำระเงินให้ลูกค้าด้วยเช็ค หรือวิธีอื่น ๆ โดยอัตโนมัติตาม Business rule ของ บลจ. เพื่อลดค่าใช้จ่ายในการชำระเงิน",
            SubContent6: "สามารถกำหนดค่าธรรมเนียมต่อธุรกรรม รวมทั้งค่าธรรมเนียมและค่าใช้จ่ายในการจัดการกองทุน ได้อย่างยืดหยุ่น ซึ่งช่วยสนับสนุนกลยุทธ์ธุรกิจ เช่นเพิ่มยอดขายโดยเมื่อลูกค้า ซื้อมากขึ้น ค่าธรรมเนียมน้อยลง",
            SubContent6_1: "ค่าธรรมเนียมคงที่ต่อธุรกรรม ค่าธรรมเนียมแปรผันตามมูลค่าของธุรกรรม และทั้งสองแบบรวมกัน",
            SubContent6_2: "แบบขั้นบันไดตามมูลค่าธุรกรรม (ลักษณะเดียวกับตารางภาษีเงินได้)",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <ul className="isabaya">
                    <li>
                        <span>{pageText.SubContent1}</span>
                        <ol style={{marginTop: "10px"}}>
                            <li style={{marginBottom: "10px"}}>{pageText.SubContent1_1}</li>
                            <li style={{marginBottom: "10px"}}>{pageText.SubContent1_2}</li>
                            <li style={{marginBottom: "10px"}}>{pageText.SubContent1_3}</li>
                            <li style={{marginBottom: "10px"}}>{pageText.SubContent1_4}</li>
                            <li style={{marginBottom: "10px"}}>{pageText.SubContent1_5}</li>
                            <li style={{marginBottom: "10px"}}>{pageText.SubContent1_6}</li>
                            <li style={{marginBottom: "10px"}}>{pageText.SubContent1_7}</li>
                            <li style={{marginBottom: "10px"}}>{pageText.SubContent1_8}</li>
                        </ol>
                    </li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                    <li><span>{pageText.SubContent4}</span></li>
                    <li><span>{pageText.SubContent5}</span></li>
                    <li>
                        <span>{pageText.SubContent6}</span>
                        <ol style={{marginTop: "15px"}}>
                            <li style={{marginBottom: "10px"}}>{pageText.SubContent6_1}</li>
                            <li style={{marginBottom: "10px"}}>{pageText.SubContent6_2}</li>
                        </ol>
                    </li>
                </ul>
                <br />
                <br />
            </div>
        );
    }
}

export default Products16