import React, { Component } from 'react'

class Products02 extends Component {

    render() {
        let pageText = {
            Header: "ระบบทะเบียนประวัติ (Personnel Record Module)",
            MainContent: "เป็นระบบย่อยของ HRNet ที่ช่วยในการจัดเก็บข้อมูลทะเบียนประวัติของพนักงาน ซึ่งบอกถึงรายละเอียดต่างๆ ที่เกี่ยวข้องกับตัวพนักงานแต่ละคน ทำให้สามารถสืบค้นประวัติของพนักงานได้อย่างง่าย ตัวอย่างความสามารถของระบบนี้ ได้แก่",
            SubContent1: "ระบบมีการบันทึกข้อมูลต่าง ๆ ของพนักงาน",
            SubContent2: "ระบบจัดเก็บข้อมูลและประวัติต่าง ๆ ข้างต้นในรูปแบบของแฟ้มข้อมูลเพื่อให้ใช้งานโดยง่ายและรวดเร็ว และสามารถเก็บข้อมูลที่เป็นรูปภาพได้",
            SubContent3: "สามารถทำการโอนย้ายหรือปรับระดับเป็นกลุ่มบุคคลได้ โดยสามารถกำหนดเงื่อนไขการโอนย้ายได้ตามคุณสมบัติที่ต้องการ เช่น กำหนดได้คนที่มีอายุงานเกิน 1 ปี ได้ 2 ขั้น ถ้าน้อยกว่า 1 ปีได้ 1 ขั้น ได้โดยการคีย์ข้อมูลครั้งเดียว",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <span>{pageText.MainContent}</span>
                <ul className="isabaya" style={{marginTop: "15px"}}>
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                </ul>
                <br />
                <br />
                <img alt="" className="img-fluid" style={{display: "block", margin: "0 auto"}} src={process.env.PUBLIC_URL + "/image/products0002.jpg"} />
            </div>
        );
    }
}

export default Products02