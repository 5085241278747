import React, { Component } from 'react'

class Products04 extends Component {

    render() {
        let pageText = {
            Header: "ระบบบริหารค่าตอบแทน (Compensation Management Module)",
            MainContent: "ระบบบริหารค่าตอบแทนสนับสนุนการจัดทำ วางแผน ตรวจสอบและควบคุมรายรับ/รายจ่ายของพนักงานได้ถูกต้องตรงเวลา การประมวลผลรายได้แต่ละงวดจะเชื่อมโยงข้อมูลกับระบบย่อยอื่น ๆ ของ HRNet เช่น ระบบบริหารเวลาปฏิบัติงาน (Time Administration) ระบบข้อมูลบุคลากร เป็นต้น เพื่อคำนวณเงินได้/เงินหักโดยอัตโนมัติตามกฎระเบียบที่องค์กรกำหนดไว้",
            SubContent1: "สามารถกำหนดโครงสร้างรายได้ที่แตกต่างกัน ให้กับพนักงานตามบริษัทย่อย หรือตามประเภทพนักงาน หรือทั้งสองอย่างได้เช่น โครงสร้างรายได้สำหรับผู้บริหาร โครงสร้างรายได้สำหรับพนักงานประจำ โครงสร้างรายได้สำหรับพนักงานชั่วคราวในแผนกบุคลากร",
            SubContent2: "ระบบสามารถรองรับรายการค่าตอบแทน ทั้งที่มีสูตรคำนวณที่แน่นอนและไม่มีสูตรคำนวณที่แน่นอนได้",
            SubContent3: "เมื่อมีการเปลี่ยนแปลงกฎเกณฑ์ต่าง ๆ ที่ใช้ในการคำนวณรายการค่าตอบแทน เช่น มีการเปลี่ยนแปลงวิธีการคำนวณภาษี หรือมีการเปลี่ยนแปลงอัตราค่าล่วงเวลา เป็นต้น ผู้ใช้สามารถทำการแก้ไขสูตรการคำนวณของรายการค่าตอบแทนนั้นได้เอง โดยที่ไม่ต้องทำการแก้ไขหรือลงโปรแกรมใหม่",
            SubContent4: "เงินเดือนหรือผลการประมวลผลเงินเดือนจะต้องผ่านการยืนยันจากผู้ที่มีสิทธิในการยืนยันเท่านั้น เพื่อเป็นการตรวจสอบความถูกต้องก่อนนำไปใช้จริง",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <span>{pageText.MainContent}</span>
                <ul className="isabaya" style={{marginTop: "15px"}}>
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                    <li><span>{pageText.SubContent4}</span></li>
                </ul>
                <br />
                <br />
                <img alt="" className="img-fluid" style={{display: "block", margin: "0 auto"}} src={process.env.PUBLIC_URL + "/image/products0004.jpg"} />
            </div>
        );
    }
}

export default Products04