import React, { Component } from 'react'

class BannerWidth12 extends Component {
  render() {
    let isBannerSlider = false;
    if(isBannerSlider === false) {
      return(
        <div className="row">
          <div className="col-sm-12" style={{paddingLeft: "0", paddingRight: "0"}}>
              <img alt="" className="img-fluid" src={this.props.Image + "/image/banner_home0001.png"} />
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="row">
          <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              {/* <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> */}
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={this.props.Image + "/image/banner_home0001.png"} className="d-block w-100" alt="..." />
              </div>
              <div className="carousel-item">
                <img src={this.props.Image + "/image/banner_products0001.png"} className="d-block w-100" alt="..." />
              </div>
              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default BannerWidth12