import React, { Component } from 'react'

class Works00 extends Component {
    
    render() {
        let pageText = {
            Product1: {Line1: "", Line2: "", Line3: ""},
            Product2: {Line1: "", Line2: "", Line3: ""},
            Product3_1: {Line1: "", Line2: "", Line3: ""},
            Product3_2: {Line1: "", Line2: "", Line3: ""},
            Product4_1: {Line1: "", Line2: "", Line3: ""},
            Product4_2: {Line1: "", Line2: "", Line3: ""},
            Product5_1: {Line1: "", Line2: "", Line3: ""},
            Product5_2: {Line1: "", Line2: "", Line3: ""}
        };
        if(this.props.lang === "en-US") {
            pageText = {
                Product1: {Line1: "โครงการพัฒนาระบบวิเคราะห์ความเสี่ยงตามหลัก", Line2: "ธรรมาภิบาลของแผนงาน/โครงการที่ของบประมาณแผ่นดิน", Line3: "สำนักงบประมาณ สำนักนายกรัฐมนตรี"}, 
                Product2: {Line1: "โครงการพัฒนาระบบจ่ายคืนผู้ฝาก", Line2: "", Line3: "สถาบันคุ้มครองเงินฝาก"}, 
                Product3_1: {Line1: "โครงการพัฒนาระบบ", Line2: "Data warehouse ข้อมูลอสังหาริมทรัพย์", Line3: "ศูนย์ข้อมูลอสังหาริมทรัพย์ ธนาคารอาคารสงเคราะห์"}, 
                Product3_2: {Line1: "โครงการพัฒนาระบบบริหารทรัพยากรบุคคล", Line2: "", Line3: "ธนาคารอาคารสงเคราะห์"}, 
                Product4_1: {Line1: "โครงการพัฒนาระบบบริการธุรกรรมโอนเงิน", Line2: "", Line3: "ธนาคาร ซีไอเอ็มบีไทย จำกัด (มหาชน)"}, 
                Product4_2: {Line1: "โครงการพัฒนาระบบอินเตอร์เน็ทแบงก์กิ้ง", Line2: "", Line3: "ธนาคาร ซีไอเอ็มบีไทย จำกัด (มหาชน)"}, 
                Product5_1: {Line1: "โครงการพัฒนาระบบโอนเงินพร้อมเพย์", Line2: "", Line3: "ธนาคาร เกียรตินาคิน จำกัด (มหาชน)"}, 
                Product5_2: {Line1: "โครงการพัฒนาระบบทะเบียน PromptPay", Line2: "", Line3: "ธนาคาร เกียรตินาคิน จำกัด (มหาชน)"} 
            };
        }
        else {
            pageText = {
                Product1: {Line1: "โครงการพัฒนาระบบวิเคราะห์ความเสี่ยงตามหลัก", Line2: "ธรรมาภิบาลของแผนงาน/โครงการที่ของบประมาณแผ่นดิน", Line3: "สำนักงบประมาณ สำนักนายกรัฐมนตรี"}, 
                Product2: {Line1: "โครงการพัฒนาระบบจ่ายคืนผู้ฝาก", Line2: "", Line3: "สถาบันคุ้มครองเงินฝาก"}, 
                Product3_1: {Line1: "โครงการพัฒนาระบบ", Line2: "Data warehouse ข้อมูลอสังหาริมทรัพย์", Line3: "ศูนย์ข้อมูลอสังหาริมทรัพย์ ธนาคารอาคารสงเคราะห์"}, 
                Product3_2: {Line1: "โครงการพัฒนาระบบบริหารทรัพยากรบุคคล", Line2: "", Line3: "ธนาคารอาคารสงเคราะห์"}, 
                Product4_1: {Line1: "โครงการพัฒนาระบบบริการธุรกรรมโอนเงิน", Line2: "", Line3: "ธนาคาร ซีไอเอ็มบีไทย จำกัด (มหาชน)"}, 
                Product4_2: {Line1: "โครงการพัฒนาระบบอินเตอร์เน็ทแบงก์กิ้ง", Line2: "", Line3: "ธนาคาร ซีไอเอ็มบีไทย จำกัด (มหาชน)"}, 
                Product5_1: {Line1: "โครงการพัฒนาระบบโอนเงินพร้อมเพย์", Line2: "", Line3: "ธนาคาร เกียรตินาคิน จำกัด (มหาชน)"}, 
                Product5_2: {Line1: "โครงการพัฒนาระบบทะเบียน PromptPay", Line2: "", Line3: "ธนาคาร เกียรตินาคิน จำกัด (มหาชน)"} 
            };
        }


        return (
            <div className="pl-md-5 pt-5 text-center" style={{width:"100%", height:"100%", backgroundColor: "white"}}>
                <div className="row">
                    <div className="col-md-6">
                        <img alt="" className="img-fluid" style={{width: "15rem"}} src={process.env.PUBLIC_URL + "/image/client0012.png"} />
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem", marginTop: "15px"}}>{pageText.Product1.Line1}</span>
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem"}}>{pageText.Product1.Line2}</span>
                        <span className="d-block" style={{fontSize: "0.92rem", marginBottom: "45px"}}>{pageText.Product1.Line3}</span>
                    </div>
                    <div className="col-md-6">
                        <img alt="" className="img-fluid" style={{width: "15rem"}} src={process.env.PUBLIC_URL + "/image/client0013.png"} />
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem", marginTop: "15px"}}>{pageText.Product2.Line1}</span>
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem"}}>{pageText.Product2.Line2}</span>
                        <span className="d-block" style={{fontSize: "0.92rem", marginBottom: "45px"}}>{pageText.Product2.Line3}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <img alt="" className="img-fluid" style={{width: "15rem"}} src={process.env.PUBLIC_URL + "/image/client0002.png"} />
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem", marginTop: "15px"}}>{pageText.Product3_1.Line1}</span>
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem"}}>{pageText.Product3_1.Line2}</span>
                        <span className="d-block" style={{fontSize: "0.92rem"}}>{pageText.Product3_1.Line3}</span>
                        
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem", marginTop: "15px"}}>{pageText.Product3_2.Line1}</span>
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem"}}>{pageText.Product3_2.Line2}</span>
                        <span className="d-block" style={{fontSize: "0.92rem", marginBottom: "45px"}}>{pageText.Product3_2.Line3}</span>
                    </div>
                    <div className="col-md-6">
                        <img alt="" className="img-fluid" style={{width: "15rem"}} src={process.env.PUBLIC_URL + "/image/client0001.png"} />
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem", marginTop: "15px"}}>{pageText.Product4_1.Line1}</span>
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem"}}>{pageText.Product4_1.Line2}</span>
                        <span className="d-block" style={{fontSize: "0.92rem"}}>{pageText.Product4_1.Line3}</span>
                        
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem", marginTop: "15px"}}>{pageText.Product4_2.Line1}</span>
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem"}}>{pageText.Product4_2.Line2}</span>
                        <span className="d-block" style={{fontSize: "0.92rem", marginBottom: "45px"}}>{pageText.Product4_2.Line3}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <img alt="" className="img-fluid" style={{width: "15rem"}} src={process.env.PUBLIC_URL + "/image/client0014.png"} />
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem"}}>{pageText.Product5_1.Line1}</span>
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem"}}>{pageText.Product5_1.Line2}</span>
                        <span className="d-block" style={{fontSize: "0.92rem"}}>{pageText.Product5_1.Line3}</span>
                        
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem", marginTop: "15px"}}>{pageText.Product5_2.Line1}</span>
                        <span className="d-block isabaya-font-green" style={{fontSize: "0.92rem"}}>{pageText.Product5_2.Line2}</span>
                        <span className="d-block" style={{fontSize: "0.92rem", marginBottom: "45px"}}>{pageText.Product5_2.Line3}</span>
                    </div>
                    <div className="col-md-6"></div>
                </div>
            </div>
        );
    }
}

export default Works00