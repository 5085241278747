import React, { Component } from 'react'

class Works05 extends Component {

    render() {
        let pageText = {
            Header1: "โครงการพัฒนาระบบบริการธุรกรรมโอนเงิน",
            Header2: "ธนาคาร ซีไอเอ็มบีไทย จำกัด (มหาชน)",
            MainContent: "ธนาคาร ซีไอเอ็มบี ไทย จำกัด (มหาชน) – โครงการพัฒนาระบบบริการธุรกรรมโอนเงิน (Funds Transfer Service Gateway) เพื่อรองรับธุรกรรมการเงินจาก Company สำหรับคำสั่งโอนเงินจากบัญชีของ Company ไปยังลูกค้าของตน (ใช้ภาษา C# NHibernate ระบบฐานข้อมูล Microsoft SQL Server) ระบบนี้ประกอบด้วยสองระบบย่อย",
            SubContent1: "บริการเว็บ (SOAP-based Web Service Gateway & RestFul API) สำหรับรับธุรกรรมโอนเงินจากระบบของ Company บันทึกลงฐานข้อมูลของระบบ ส่งธุรกรรมโอนเงินไปยังระบบต่าง ๆ ดังนี้",
            SubContent1_1: "ระบบ Core Banking หากบัญชีปลายทางในธุรกรรมเป็นของธนาคาร",
            SubContent1_2: "ระบบ on-line หากบัญชีปลายทางในธุรกรรมเป็นของธนาคารอื่น และเป็นธุรกรรมด่วน",
            SubContent1_3: "ระบบบการตรวจสอบ Watchlist ทั้งผู้โอนเงินและผู้รับเงิน",
            SubContent1_4: "รองรับธุรกรรมโอนเงินแบบ Real Time ซึ่งประมวลผลทันที และรับธุรกรรมโอนเงินแบบ batch เพื่อประมวลผลภายหลัง",
            SubContent2: "ระบบ web-based application สำหรับบุคลากรของธนาคารดูสถานะของธุรกรรม และออกรายงาน",
            SubContent3: "ระบบ web-based application สำหรับบุคลากรของ Company ดูสถานะของธุรกรรม และออกรายงาน",
        };


        return (
            <div className="pl-md-5 pt-5" style={{width:"100%", height:"100%", backgroundColor: "white"}}>
                <h5 className="isabaya-font-green">{pageText.Header1}</h5>
                <h6 className="isabaya-font-green">{pageText.Header2}</h6>
                <br />
                <span className="d-block">{pageText.MainContent}</span>
                <br />
                <ul className="isabaya">
                    <li>
                        <span>{pageText.SubContent1}</span>
                        <ol>
                            <li>{pageText.SubContent1_1}</li>
                            <li>{pageText.SubContent1_2}</li>
                            <li>{pageText.SubContent1_3}</li>
                            <li>{pageText.SubContent1_4}</li>
                        </ol>
                    </li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                </ul>
                <br />
                <br />
            </div>
        );
    }
}

export default Works05