import React, { Component } from 'react'
import ChangeLanguage from './ChangeLanguage'
import Logo from './Logo'
import Navbar from './Navbar'

export default class Header extends Component {
    render() {
        return (
            <div id="header">
                <ChangeLanguage handleClick={this.props.handleAppLangChanged} />
                <Logo />
                <Navbar lang={this.props.lang} />
            </div>
        )
    }
}