import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'

class NavbarWorks extends Component {
  render() {
    let headerText = "";
    let menuText = ["", "", "", "", "", "", "", ""];
    if(this.props.lang === "en-US") {
        headerText = "ความสำเร็จในการออกแบบและพัฒนาระบบสารสนเทศ";
        menuText = 
        [
            "โครงการพัฒนาระบบวิเคราะห์ความเสี่ยงตามหลักธรรมาภิบาลของแผนงาน/โครงการที่ของบประมาณแผ่นดิน",
            "โครงการพัฒนาระบบจ่ายคืนผู้ฝาก",
            "โครงการพัฒนาระบบ Data warehouse ข้อมูลอสังหาริมทรัพย์",
            "โครงการพัฒนาระบบบริหารทรัพยากรบุคคล",
            "โครงการพัฒนาระบบบริการธุรกรรมโอนเงิน",
            "โครงการพัฒนาระบบอินเตอร์เน็ทแบงก์กิ้ง",
            "โครงการพัฒนาระบบโอนเงินพร้อมเพย์",
            "โครงการพัฒนาระบบทะเบียน PromptPay"
        ];
    }
    else {
        headerText = "ความสำเร็จในการออกแบบและพัฒนาระบบสารสนเทศ";
        menuText = 
        [
            "โครงการพัฒนาระบบวิเคราะห์ความเสี่ยงตามหลักธรรมาภิบาลของแผนงาน/โครงการที่ของบประมาณแผ่นดิน",
            "โครงการพัฒนาระบบจ่ายคืนผู้ฝาก",
            "โครงการพัฒนาระบบ Data warehouse ข้อมูลอสังหาริมทรัพย์",
            "โครงการพัฒนาระบบบริหารทรัพยากรบุคคล",
            "โครงการพัฒนาระบบบริการธุรกรรมโอนเงิน",
            "โครงการพัฒนาระบบอินเตอร์เน็ทแบงก์กิ้ง",
            "โครงการพัฒนาระบบโอนเงินพร้อมเพย์",
            "โครงการพัฒนาระบบทะเบียน PromptPay"
        ];
    }

    return (
        <div className="pl-4 pt-4 pr-4" style={{ backgroundColor: "rgb(231,231,223)", height: "100%" }}>
            <h5 className="isabaya-pointer"><Link to="/works" className="isabaya-font-normal-static" style={{textDecoration: "none"}}>{headerText}</Link></h5>
            <ul className="nav flex-column isabaya-simple-menu" style={{paddingLeft: "40px"}}>
                <li><NavLink to={"/works/work01"} activeClassName="active">{menuText[0]}</NavLink></li>
                <li><NavLink to={"/works/work02"} activeClassName="active">{menuText[1]}</NavLink></li>
                <li><NavLink to={"/works/work03"} activeClassName="active">{menuText[2]}</NavLink></li>
                <li><NavLink to={"/works/work04"} activeClassName="active">{menuText[3]}</NavLink></li>
                <li><NavLink to={"/works/work05"} activeClassName="active">{menuText[4]}</NavLink></li>
                <li><NavLink to={"/works/work06"} activeClassName="active">{menuText[5]}</NavLink></li>
                <li><NavLink to={"/works/work07"} activeClassName="active">{menuText[6]}</NavLink></li>
                <li><NavLink to={"/works/work08"} activeClassName="active">{menuText[7]}</NavLink></li>
            </ul>
            <br />
        </div>
    );
  }
}

export default NavbarWorks