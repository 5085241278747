import React, { Component } from 'react'

class Products05 extends Component {

    render() {
        let pageText = {
            Header: "ระบบสวัสดิการ (Benefits Module)",
            MainContent: "ระบบย่อยของ HRNet ระบบนี้ช่วยในการวางแผนและจัดการสวัสดิการที่องค์กรมีให้กับพนักงาน ได้อย่างมีประสิทธิภาพ เพื่อจูงใจให้พนักงานทำงานให้กับองค์กรอย่างมีประสิทธิภาพ และเพื่อประเมินงบประมาณที่องค์กรต้องจัดสรรให้กับสวัสดิการเหล่านั้น ตัวอย่างความสามารถของระบบนี้มีดังนี้",
            SubContent1: "สนับสนุนการจัดการโครงสร้างทางด้านสวัสดิการพื้นฐานขององค์กร เช่น ค่ารักษาพยาบาล กองทุนสำรองเลี้ยงชีพ ประกันอุบัติเหตุ สวัสดิการเงินกู้ยืมต่าง ๆ ค่าเช่าบ้าน สวัสดิการอื่น ๆ ที่ทางองค์กรเห็นว่าเหมาะสมและมีประโยชน์ต่อบุคลากร ระบบจะรองรับการเพิ่มรายการสวัสดิการได้ไม่จำกัด และสามารถจัดเป็นกลุ่มสวัสดิการ จัดเป็นแผนสวัสดิการ รวมถึงจัดเป็นโปรแกรมสวัสดิการได้",
            SubContent2: "องค์กรสามารถกำหนดค่าใช้จ่ายในการเป็นสมาชิกสวัสดิการ ค่าใช้จ่ายจะแบ่งเป็น 2 ส่วน ได้แก่ ส่วนที่บุคลากรต้องออกเองและส่วนที่ทางองค์กรออกให้อาจกำหนดเป็นอัตราคงที่หรือกำหนดเป็นสูตรที่ขึ้นอยู่กับเงินเดือน อายุงาน เป็นต้น",
            SubContent3: "ค่าใช้จ่ายในการเป็นสมาชิกสวัสดิการ เช่น เบี้ยประกัน สามารถนำไปหักจากเงินเดือน โดยส่งข้อมูลจากระบบสวัสดิการไปยังระบบเงินเดือนอัตโนมัติ",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <span>{pageText.MainContent}</span>
                <ul className="isabaya" style={{marginTop: "15px"}}>
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                </ul>
                <br />
                <br />
                <img alt="" className="img-fluid" style={{display: "block", margin: "0 auto"}} src={process.env.PUBLIC_URL + "/image/products0005.jpg"} />
            </div>
        );
    }
}

export default Products05