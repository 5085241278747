import React, { Component } from 'react'

class ChangeLanguage extends Component {

  render() {
    return (
      <div className="row" style={{ backgroundColor: "rgb(244,244,244)" }}>
        <div className="col-sm-1"></div>
        <div className="col-sm-10 text-right">
          <span style={{ fontSize: "12px", fontWeight: "bold" }}>
            <a href="/" id="th" className="text-dark" onClick={this.props.handleClick} lang="th-TH">TH</a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="/" id="en" className="text-dark" onClick={this.props.handleClick} lang="en-US">EN</a>
          </span>
        </div>
        <div className="col-sm-1"></div>
      </div>
    );
  }
}

export default ChangeLanguage