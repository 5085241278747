import React, { Component } from 'react'

class Products11 extends Component {

    render() {
        let pageText = {
            Header: "ระบบความมั่นคง (Security Module)",
            MainContent: "เป็นระบบย่อยของ HRNet ที่สนับสนุนด้านความปลอดภัยของระบบ เนื่องจากระบบนี้ถูกออกแบบมาให้มีการจัดกลุ่มผู้ใช้ และกำหนดสิทธิ์ให้แก่กลุ่มผู้ใช้ ในการเข้า เรียกดู และแก้ไขข้อมูลต่างๆ ซึ่งเป็นตัวสนับสนุนให้ระบบมีความปลอดภัยสูง",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <span>{pageText.MainContent}</span>
                <br />
                <br />
            </div>
        );
    }
}

export default Products11