import React, { Component } from 'react'

class Products12 extends Component {

    render() {
        let pageText = {
            Header: "ระบบสารสนเทศสำหรับผู้บริหาร (Management Information System Module)",
            MainContent: "ระบบข้อมูลเพื่อการบริหาร คือ ระบบจัดการข้อมูลระบบบริหารบุคคลผ่านทางเครือข่ายอินทราเน็ต เพื่อผู้บริหารในการดูข้อมูลสรุปโดยรวมขององค์กร โดยมีข้อมูลใน 5 ระบบ ดังนี้",
            SubContent1: "ระบบการพนักงาน",
            SubContent2: "ระบบเงินเดือนและสวัสดิการ",
            SubContent3: "ระบบเวลาปฏิบัติงาน",
            SubContent4: "ระบบฝึกอบรม",
            SubContent5: "Query Tool",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <span>{pageText.MainContent}</span>
                <ul className="isabaya" style={{marginTop: "15px"}}>
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                    <li><span>{pageText.SubContent4}</span></li>
                    <li><span>{pageText.SubContent5}</span></li>
                </ul>
                <br />
                <br />
                <img alt="" className="img-fluid" style={{display: "block", margin: "0 auto"}} src={process.env.PUBLIC_URL + "/image/products0012.png"} />
            </div>
        );
    }
}

export default Products12