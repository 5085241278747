import React, { Component } from 'react'

class Products13 extends Component {

    render() {
        let pageText = {
            Header: "ระบบบริการตัวเอง (Employee Self Services)",
            MainContent: "",
            SubContent1: "สามารถบันทึกรายละเอียดการขอเบิกสวัสดิการได้",
            SubContent2: "สามารถสอบถามรายละเอียดสวัสดิการต่างๆและตรวจสอบยอดสะสมรายปีของพนักงานได้",
            SubContent3: "สามารถสอบถามข้อมูลต่างๆของตนเองทั้งหมด เช่น ประวัติส่วนบุคคล ประวัติการทำงาน ประวัติการเลื่อนขั้นเงินเดือน ประวัติการลงโทษ ประวัติการศึกษา ประวัติการฝึกอบรม และประวัติการได้รับเครื่องราชฯ เป็นต้น",
            SubContent4: "สามารถ บันทึกรายละเอียดการหักภาษี ณ ที่จ่าย เช่น ค่าลดหย่อน สามารถสอบถามข้อมูลอื่นๆ เช่น ปฏิทินวันหยุดประจำปี แผนการฝึกอบรมประจำปีงบประมาณ เป็นต้น",
            SubContent5: "สามารถสอบถามสถานะของเอกสารการเบิกสวัสดิการอยู่ในขั้นตอนใด โดยสามารถค้นหาได้จาก รหัสประจำตัวพนักงาน ชื่อ-นามสกุล เป็นต้น",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <ul className="isabaya">
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                    <li><span>{pageText.SubContent3}</span></li>
                    <li><span>{pageText.SubContent4}</span></li>
                    <li><span>{pageText.SubContent5}</span></li>
                </ul>
                <br />
                <br />
            </div>
        );
    }
}

export default Products13