import React, { Component } from 'react'

class BannerWidth10 extends Component {
    render() {
      return (
        <div className="row" style={{ backgroundColor: "rgb(244,244,244)" }}>
          <div className="col-sm-1"></div>
          <div className="col-sm-10">
            <img alt="" className="img-fluid" src={this.props.Image} />
          </div>
          <div className="col-sm-1"></div>
        </div>
      );
    }
  }

  export default BannerWidth10