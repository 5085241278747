import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

class Navbar extends Component {
  render() {
    let menuTitle = [];
    if (this.props.lang === "en-US") { menuTitle = ["Home", "About us", "Services", "Products", "Works"] }
    else { menuTitle = ["หน้าหลัก", "เกี่ยวกับเรา", "บริการ", "ผลิตภัณฑ์", "ผลงาน"] }

    return (
      <div className="row" style={{ backgroundColor: "rgb(244,244,244)" }}>
        <div className="col-lg-12 col-sm-12">
          <nav className="navbar navbar-light navbar-expand-sm" style={{ backgroundColor: "rgb(244,244,244)" }}>
            <div className="container justify-content-lg-end">
              <ul className="nav navbar-nav flex-row">
                <li><NavLink exact to={"/"} id="nav_home" activeClassName="active" className="isabaya-nav-item">{menuTitle[0]}</NavLink></li>
                <li><NavLink to={"/aboutus"} id="nav_aboutus" activeClassName="active" className="isabaya-nav-item">{menuTitle[1]}</NavLink></li>
                <li><NavLink to={"/services"} id="nav_services" activeClassName="active" className="isabaya-nav-item">{menuTitle[2]}</NavLink></li>
                <li><NavLink to={"/products"} id="nav_products" activeClassName="active" className="isabaya-nav-item">{menuTitle[3]}</NavLink></li>
                <li><NavLink to={"/works"} id="nav_works" activeClassName="active" className="isabaya-nav-item">{menuTitle[4]}</NavLink></li>
              </ul>
            </div>
          </nav>
          <div className="d-none">
            <NavLink to={"/products/producthrnet01"} id="nav_producthrnet01"></NavLink>
            <NavLink to={"/products/producthrnet02"} id="nav_producthrnet02"></NavLink>
            <NavLink to={"/products/producthrnet03"} id="nav_producthrnet03"></NavLink>
            <NavLink to={"/products/producthrnet04"} id="nav_producthrnet04"></NavLink>
            <NavLink to={"/products/producthrnet05"} id="nav_producthrnet05"></NavLink>
            <NavLink to={"/products/producthrnet06"} id="nav_producthrnet06"></NavLink>
            <NavLink to={"/products/producthrnet07"} id="nav_producthrnet07"></NavLink>
            <NavLink to={"/products/producthrnet08"} id="nav_producthrnet08"></NavLink>
            <NavLink to={"/products/producthrnet09"} id="nav_producthrnet09"></NavLink>
            <NavLink to={"/products/producthrnet10"} id="nav_producthrnet10"></NavLink>
            <NavLink to={"/products/producthrnet11"} id="nav_producthrnet11"></NavLink>
            <NavLink to={"/products/producthrnet12"} id="nav_producthrnet12"></NavLink>
            <NavLink to={"/products/producthrnet13"} id="nav_producthrnet13"></NavLink>
            <NavLink to={"/products/producthrnet14"} id="nav_producthrnet14"></NavLink>
            <NavLink to={"/products/producthrnet15"} id="nav_producthrnet15"></NavLink>
            <NavLink to={"/products/productimsabaya16"} id="nav_productimsabaya16"></NavLink>
            <NavLink to={"/products/productimsabaya17"} id="nav_productimsabaya17"></NavLink>
            <NavLink to={"/works/work01"} id="nav_work01"></NavLink>
            <NavLink to={"/works/work02"} id="nav_work02"></NavLink>
            <NavLink to={"/works/work03"} id="nav_work03"></NavLink>
            <NavLink to={"/works/work04"} id="nav_work04"></NavLink>
            <NavLink to={"/works/work05"} id="nav_work05"></NavLink>
            <NavLink to={"/works/work06"} id="nav_work06"></NavLink>
            <NavLink to={"/works/work07"} id="nav_work07"></NavLink>
            <NavLink to={"/works/work08"} id="nav_work08"></NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar