import React, { Component } from 'react'

class Works02 extends Component {

    render() {
        let pageText = {
            Header1: "โครงการพัฒนาระบบจ่ายคืนผู้ฝาก",
            Header2: "สถาบันคุ้มครองเงินฝาก (Deposit Protection Agency)",
            MainContent: "โครงการพัฒนาระบบจ่ายคืนผู้ฝาก (ในกรณีที่ธนาคารล้มละลาย) ประกอบด้วยสองส่วนดังนี้",
            SubContent1: "ระบบ web-service (ใช้ภาษา C#) เพื่อให้บริการรัน SSIS Package จาก web-based application",
            SubContent2: "ระบบนำเข้าข้อมูลผู้ฝากเงินของธนาคาร โดยใช้เครื่องมือ SSIS (Microsoft SQL Server Integration Services) และฐานข้อมูล Microsoft SQL Server พัฒนาเป็น SSIS packages จำนวน 3 packages ได้แก่",
            SubContent2_1: "package นำเข้าและตรวจสอบความถูกต้องของรูปแบบข้อมูลของลูกค้าธนาคารในรูปแบบ XML จำนวน 8 แฟ้ม (ประเภท)",
            SubContent2_2: "package ตรวจสอบความถูกต้องของข้อมูลโดยการ cross-check ข้อมูลที่ซับซ้อน",
            SubContent2_3: "และ package สุดท้าย ทำหน้าที่คำนวณจำนวนเงินที่จะต้องจ่ายคืนผู้ฝาก หลังจากหักภาระหนี้ต่าง ๆ ตามเงื่อนไขที่กำหนดโดยกฎหมาย",
            FooterContent: "แต่ละ package จะประกอบด้วย Control-flow ซึ่งเป็นส่วนกำหนดลำดับการทำงานแบบ Parallel หรือแบบ Sequential ของชิ้นส่วนต่าง ๆ เช่น ชิ้นส่วนที่เป็นโปรแกรม ชิ้นส่วนที่เป็น SQL script และชิ้นส่วน data-flow เป็นต้น ภายในของชิ้นส่วน data-flow จะเป็นผังของชิ้นส่วนอีกกลุ่มหนึ่ง ซึ่งใช้อ่าน บันทึก หรือประมวลผลข้อมูลทีละ record",
            FigureDescription1: "Control Flow แบบ sequential ของการตรวจสอบข้อมูล",
            FigureDescription2: "Data Flow ของ Validate (1) Customers ซึ่งอ่านข้อมูลลูกค้าจากฐานข้อมูลและตรวจสอบทีละราย",
        };


        return (
            <div className="pl-md-5 pt-5" style={{width:"100%", height:"100%", backgroundColor: "white"}}>
                <h5 className="isabaya-font-green">{pageText.Header1}</h5>
                <h6 className="isabaya-font-green">{pageText.Header2}</h6>
                <br />
                <span className="d-block">{pageText.MainContent}</span>
                <br />
                <ul className="isabaya">
                    <li><span>{pageText.SubContent1}</span></li>
                    <li>
                        <span>{pageText.SubContent2}</span>
                        <ol>
                            <li>{pageText.SubContent2_1}</li>
                            <li>{pageText.SubContent2_2}</li>
                            <li>{pageText.SubContent2_3}</li>
                        </ol>
                    </li>
                </ul>
                <span className="d-block">{pageText.FooterContent}</span>
                <br />
                <div className="p-3 m-auto text-center" style={{border: "2px solid rgb(156,148,1)", width:"95%"}}>
                    <img alt="" className="d-block img-fluid" style={{marginBottom: "5px"}} src={process.env.PUBLIC_URL + "/image/works0003.png"} />
                    <span style={{fontSize: "0.85rem"}}>{pageText.FigureDescription1}</span>
                </div>
                <br />
                <div className="p-3 m-auto text-center" style={{border: "2px solid rgb(156,148,1)", width:"95%"}}>
                    <img alt="" className="d-block img-fluid" style={{marginBottom: "5px"}} src={process.env.PUBLIC_URL + "/image/works0004.png"} />
                    <span style={{fontSize: "0.85rem"}}>{pageText.FigureDescription2}</span>
                </div>
                <br />
                <br />
            </div>
        );
    }
}

export default Works02