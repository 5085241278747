import React, { Component } from 'react'

class Works06 extends Component {

    render() {
        let pageText = {
            Header1: "โครงการพัฒนาระบบอินเตอร์เน็ทแบงก์กิ้ง",
            Header2: "ธนาคาร ซีไอเอ็มบีไทย จำกัด (มหาชน)",
            MainContent: "โครงการพัฒนาระบบอินเตอร์เน็ตแบงก์กิ้ง สำหรับลูกค้าที่เป็นองค์กรธุรกิจตั้งแต่ระดับ Small business ถึงระดับ Enterprise ระบบสามารถรองรับธุรกรรมการเงินได้หลายประเภท  (Payroll reimbursement, bill payment, ) และจะส่งคำสั่งไปยังระบบ Core Banking ของธนาคาร เพื่อประมวลผลธุรกรรมการเงิน ระบบนี้มีเครื่องมือให้ลูกค้าของธนาคารกำหนดเงื่อนไขในการพิจารณาธุรกรรมการเงินแต่ละประเภทได้อย่างรอบคอบ รวมทั้งสิทธิของผู้ใช้แต่ละรายของลูกค้าด้วย RBAC (Role-Based Access Control) ระบบนี้ยังใช้บริการของระบบอื่น ๆ ของธนาคาร ได้แก่ Directory Server เพื่อตรวจสอบเอกลักษณ์ของผู้ใช้ที่เป็นพนักงานธนาคาร, SMS Server, Email Server, Fax Server (โดยใช้การวิเคราะห์และออกแบบเชิงวัตถุ UML พัฒนาด้วย ASP.Net ภาษา C# ระบบฐานข้อมูล Microsoft SQL Server)",
            FigureDescription1: "A business process diagram in BPMN of the inter-bank fund transfer transaction",
        };


        return (
            <div className="pl-md-5 pt-5" style={{width:"100%", height:"100%", backgroundColor: "white"}}>
                <h5 className="isabaya-font-green">{pageText.Header1}</h5>
                <h6 className="isabaya-font-green">{pageText.Header2}</h6>
                <br />
                <span className="d-block">{pageText.MainContent}</span>
                <br />
                <div className="p-3 m-auto text-center" style={{border: "2px solid rgb(156,148,1)", width:"95%"}}>
                    <img alt="" className="d-block img-fluid" style={{marginBottom: "5px"}} src={process.env.PUBLIC_URL + "/image/works0005.png"} />
                    <span style={{fontSize: "0.85rem"}}>{pageText.FigureDescription1}</span>
                </div>
                <br />
                <br />
            </div>
        );
    }
}

export default Works06