import React, { Component } from 'react'
import BannerWidth10 from '../components/BannerWidth10'


class AboutUs extends Component {
    
    render() {
        let pageText = {
            Header1:"บริษัท ไอสัปปายะ จำกัด ก่อตั้งขึ้นในปี พ.ศ. 2549 โดย ผศ. ดร. สุพจน์ สุตัณฑวิบูลย์ ผู้ทำงานด้านซอฟท์แวร์มากว่า 30 ปี เพื่อนำความรู้และประสบการณ์ มาให้บริการออกแบบและพัฒนาซอฟท์แวร์ที่มีความเชื่อถือได้สูง (Highly Reliable) ให้สำเร็จได้อย่างรวดเร็ว (Rapid development) โดยใช้นวัตกรรม iSabaya Framework ซึ่งประกอบด้วยฐานข้อมูลและชิ้นส่วนซอฟท์แวร์ที่ยืดหยุ่น ถูกออกแบบมาเพื่อรองรับการพัฒนาซอฟท์แวร์ได้หลากหลายประเภท ไม่ว่าจะเป็นระบบสื่อสารข้อมูล ระบบบริการเว็บ (ทั้ง SOAP-based Web Services & REST Web APIs) ระบบประมวลผลธุรกรรมการเงิน (Financial Transaction Processing Systems) ตลอดจนถึงระบบสารสนเทศทางธุรกิจทั่วไป การใช้ iSabaya Framework สามารถลดเวลาในการพัฒนาซอฟท์แวร์ลงได้ถึง 30% - 80% ในขณะที่ได้ซอฟท์แวร์ที่มีคุณภาพสูงกว่าการพัฒนาแบบเดิม ๆ (Traditional Software Development)",
            Header2:"iSabaya Framework ได้รับการออกแบบและพัฒนา โดยประยุกต์ เทคโนโลยีเชิงวัตถุ (Object-oriented Technology) และสถาปัตยกรรมแบบ Layered Software Architecture ตลอดจนพัฒนาการทดสอบ (unit tests และ stress tests) เพื่อให้ได้ Framework ที่มีคุณภาพ สามารถรองรับการขยาย (เพิ่มความสามารถใหม่ ๆ) ได้อย่างต่อเนื่อง และเป็นระบบ iSabaya Framework นี้เป็นทั้ง reusable code และ design patterns ประกอบด้วย Object-Oriented classes, Object-Relation Mappings, Unit Tests and Test Suites, and Database ฯลฯ สามารถนำมาต่อยอดในการพัฒนาซอฟท์แวร์ที่มีความเชื่อถือได้สูง (High Reliability) ได้หลากหลายประเภทในเวลาอันรวดเร็ว เช่น ระบบประมวลผลธุรกรรมการเงิน (Financial Transaction Processing Systems) ระบบสื่อสารข้อมูล ระบบสารสนเทศ ระบบซอฟท์แวร์ให้บริการทั้งแบบ Web Services และ Web API ฯลฯ",
            SectionSystemInformation:{
                Header1: "",
                Header2: "",
                Item01: "PromptPay systems for a Thai Bank (PromptPay is an integrated set of national electronic financial transaction service using citizen identity number and/or mobile phone numbers initiated by the Thai government in 2015)",
                Item02: "Project Risk Assessment (web-based) system for the Bureau of Budgeting, the Office of the Prime Minister",
                Item03: "DLT Check in (Android & IOS).",
                Item04: "Web Service gateway transaction between CIMB Thai and TRUE MONEY.",
                Item05: "Internet banking system for CIMB Thai serving SME to enterprise-level customers.",
                Item06: "Depositor reimbursement processing system for the Deposit Protection Agency",
                Item07: "Human Resource Management System for the Government Housing Bank.",
                Item08: "Business Intelligent tool for National Housing Authority .",
                Item09: "Business intelligent tool for the Secretariat office, Ministry of Commerce.",
                Item10: "National Institute of Development Administration, Bangkok, Thailand [ Develop a program for analyzing multiple-choice examination questions to evaluate the difficulty and discriminating power of each test question.  This program is being used in the analysis of entrance examination paper ]",
                Item11: "Labour Ministry [ development of the web site for matching employers and job applicants ]",
                Item12: "Social Security Office [development of MIS prototype ]",
                Item13: "IT Master Plan for  the Department of Science Service, Ministry of Science and Technology",
                Item14: "Royal Thai Navy based on HP test equipment and computer [Developer of a system that automates the quality assurance processes of communication equipment ]",
                Item15: "Develop a graph editor in C on Sun Window system in 1989.  This program allows user to construct graphs (network) using a set icons (template) that represents abstract systems, e.g. a real time application similar to Visio.  The set of icons can be added or replaced by the users.",
                Item16: "Develop automatic theorem provers in LISP and Prolog language on a BSD UNIX system.",
                Item17: "Project leader, architect, database designer, system analyst, and user interface designer in the development of a 3-tier international human resource management system using UML (Unified Modeling Language), Visual Basic 6, ADO, and MS SQL Server.",
                Item18: "Project leader, architect, system analyst, database designer, and user interface designer in the development of a 3-tier university student registry system using UML, Visual Basic 6, ADO, and MS SQL Server.",
                Item19: <span>Author of  <a href="https://en.wikipedia.org/wiki/Xfig" target="_blank" rel="noopener noreferrer" style={{color: "royalblue",textDecoration: "underline !important"}}>XFig</a>, an open source WYSIWYG (What You See Is What You Get) drawing program on  Sun Window system in C language and the accompanied filter programs that  translate drawings into other languages such as Postscript. It is now a part of  X Windows release.</span>,
            },
            SectionConsultant:{
                Header1: "",
                Header2: "",
                Item01: "ICT Consultant of the Information Center of the Prime Minister Secretariat Office.",
                Item02: "Principal consultant of the development of the MIS Master Plan of the Ministry of Education.",
                Item03: "Department of Science Services, the Ministry of Science and Technology [ Development of the Information Technology Master Plan ]",
                Item04: "Consultant of Paiboon Insurance Co., Ltd. in information technology",
                Item05: "Consultant in the computerization plan of the Custom Department, Ministry of Finance.  Design the network architecture, infrastructure, and the computing resources of the project.",
                Item06: "Consultant in the computerization plan of Police department in the area of criminal and other cases.",
                Item07: "Principal consultant in the development of communication master plan for the Ministry of Foreign Affair.",
                Item08: "Principal consultant in the implementation of the data-communication network between major Thai consulates and embassies and the Ministry of Foreign Affair.",
                Item09: "Provided consulting services to UCOM (United Communication Co., Ltd.) on the objected-oriented analysis and design using UML (Unified Modeling Language).  Contributions included the transfer of knowledge of objected-oriented programming and software engineering methodologies to UCOM’s software engineers, and analysts as well as advising and overseeing a pilot project developed using the mentioned technologies.",
                Item10: "Evaluator of the project “Development of the database for performance indicator of local administrative agencies” of the National Economic and Social Development Council.",
                Item11: "Advisor of the development of MIS Master Plan for the Ministry of Education.",
                Item12: "Advisor of the Information Center of the Secretariat Office of the Prime Minister",
                Item13: "Chief Designer and Consultant to the development of comprehensive information systems of the National Health Security Office",
                Item14: "Chief Designer in the analysis and design of the Executive Information System and the Human Resource Management System of the Mahachulalongkornrajavidyalaya University (MCU)",
                Item15: "Developed the database and a business intelligence (BI) tool in a project of National Housing Authority to develop the database of community administration and the life quality indicator (โครงการพัฒนาระบบฐานข้อมูลการบริหารงานชุมชน ตัวชี้วัดคุณภาพชีวิตและการสร้างวัฒนธรรมเอื้ออาทรในโครงการของการเคหะแห่งชาติ)",
                Item16: "Advisor of the Information System Center of the Royal Irrigation Department, The Ministry of Agriculture and Cooperatives",
                Item17: "Developed a business intelligence (BI) tool in the Executive Information System (EIS) development project of the Office of the Permanent Secretary, the Ministry of Commerce",
                Item18: "Developed the data warehouse of the Real Estate Information Center, The Government Housing Bank",
                Item19: "Information System Expert in the Central Database Development of the Office of National Economic and Social Development Committee (formerly NESDB)",
            },
        };
        if (this.props.lang === "en-US") {
            pageText.SectionSystemInformation.Header1 = "System information";
            pageText.SectionSystemInformation.Header2 = "(Ph.D.Supoj Sutanthavibul)";
            pageText.SectionConsultant.Header1 = "Consultant";
            pageText.SectionConsultant.Header2 = "(Ph.D.Supoj Sutanthavibul)";
        } 
        else {
            pageText.SectionSystemInformation.Header1 = "ผลงานโครงการและระบบสารสนเทศ";
            pageText.SectionSystemInformation.Header2 = "(ผศ.ดร. สุพจน์ สุตัณฑวิบูลย์)";
            pageText.SectionConsultant.Header1 = "ผลงานที่ปรึกษา";
            pageText.SectionConsultant.Header2 = "(ผศ.ดร. สุพจน์ สุตัณฑวิบูลย์)";
        }


        return (
            <div>
                <BannerWidth10 Image={process.env.PUBLIC_URL + "/image/banner_aboutus0001.png"} />
                <div className="row" style={{ backgroundColor: "rgb(244,244,244)" }}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <div className="p-4 p-sm-5">
                            <span className="d-block">{pageText.Header1}</span>
                            <br />
                            <span className="d-block">{pageText.Header1}</span>
                            <br />
                            <br />
                            <h5 className="isabaya-font-green">{pageText.SectionSystemInformation.Header1}</h5>
                            <h6 className="isabaya-font-green">{pageText.SectionSystemInformation.Header2}</h6>
                            <ul className="isabaya" style={{marginTop: "15px"}}>
                                <li><span>{pageText.SectionSystemInformation.Item01}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item02}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item03}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item04}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item05}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item06}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item07}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item08}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item09}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item10}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item11}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item12}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item13}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item14}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item15}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item16}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item17}</span></li>
                                <li><span>{pageText.SectionSystemInformation.Item18}</span></li>
                                <li>{pageText.SectionSystemInformation.Item19}</li>
                            </ul>
                            <br />
                            <br />
                            <h5 className="isabaya-font-green">{pageText.SectionConsultant.Header1}</h5>
                            <h6 className="isabaya-font-green">{pageText.SectionConsultant.Header2}</h6>
                            <ul className="isabaya" style={{marginTop: "15px"}}>
                                <li><span>{pageText.SectionConsultant.Item01}</span></li>
                                <li><span>{pageText.SectionConsultant.Item02}</span></li>
                                <li><span>{pageText.SectionConsultant.Item03}</span></li>
                                <li><span>{pageText.SectionConsultant.Item04}</span></li>
                                <li><span>{pageText.SectionConsultant.Item05}</span></li>
                                <li><span>{pageText.SectionConsultant.Item06}</span></li>
                                <li><span>{pageText.SectionConsultant.Item07}</span></li>
                                <li><span>{pageText.SectionConsultant.Item08}</span></li>
                                <li><span>{pageText.SectionConsultant.Item09}</span></li>
                                <li><span>{pageText.SectionConsultant.Item10}</span></li>
                                <li><span>{pageText.SectionConsultant.Item11}</span></li>
                                <li><span>{pageText.SectionConsultant.Item12}</span></li>
                                <li><span>{pageText.SectionConsultant.Item13}</span></li>
                                <li><span>{pageText.SectionConsultant.Item14}</span></li>
                                <li><span>{pageText.SectionConsultant.Item15}</span></li>
                                <li><span>{pageText.SectionConsultant.Item16}</span></li>
                                <li><span>{pageText.SectionConsultant.Item17}</span></li>
                                <li><span>{pageText.SectionConsultant.Item18}</span></li>
                                <li><span>{pageText.SectionConsultant.Item19}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div>
            </div>
        );
    }
}

export default AboutUs