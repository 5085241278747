import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'

class NavbarProducts extends Component {
  render() {
    
      return (
        <div className="pl-4 pt-4 pr-4" style={{ backgroundColor: "rgb(231,231,223)", height: "100%" }}>
            <h5 className="isabaya-pointer"><Link to="/products" className="isabaya-font-normal-static" style={{textDecoration: "none"}}>HRNet Products</Link></h5>
            <ul className="nav flex-column isabaya-simple-menu" style={{paddingLeft: "40px"}}>
                <li><NavLink to={"/products/producthrnet01"} activeClassName="active">ระบบโครงสร้างองค์กร</NavLink></li>
                <li><NavLink to={"/products/producthrnet02"} activeClassName="active">ระบบทะเบียนประวัติ</NavLink></li>
                <li><NavLink to={"/products/producthrnet03"} activeClassName="active">ระบบสรรหาบุคลากร</NavLink></li>
                <li><NavLink to={"/products/producthrnet04"} activeClassName="active">ระบบบริหารค่าตอบแทน</NavLink></li>
                <li><NavLink to={"/products/producthrnet05"} activeClassName="active">ระบบสวัสดิการ</NavLink></li>
                <li><NavLink to={"/products/producthrnet06"} activeClassName="active">ระบบบริหารเวลาปฏิบัติงาน<br />(Window Application)</NavLink></li>
                <li><NavLink to={"/products/producthrnet07"} activeClassName="active">ระบบบริหารเวลาปฏิบัติงาน<br />(Web Application)</NavLink></li>
                <li><NavLink to={"/products/producthrnet08"} activeClassName="active">ระบบระบบการลา</NavLink></li>
                <li><NavLink to={"/products/producthrnet09"} activeClassName="active">ระบบพัฒนาบุคลากร</NavLink></li>
                <li><NavLink to={"/products/producthrnet10"} activeClassName="active">ระบบประเมิน</NavLink></li>
                <li><NavLink to={"/products/producthrnet11"} activeClassName="active">ระบบความมั่นคง</NavLink></li>
                <li><NavLink to={"/products/producthrnet12"} activeClassName="active">ระบบสารสนเทศสำหรับผู้บริหาร</NavLink></li>
                <li><NavLink to={"/products/producthrnet13"} activeClassName="active">ระบบบริการตัวเอง</NavLink></li>
                <li><NavLink to={"/products/producthrnet14"} activeClassName="active">ระบบงานคำสั่ง</NavLink></li>
                <li><NavLink to={"/products/producthrnet15"} activeClassName="active">ระบบ Work flow</NavLink></li>
            </ul>
            <br />
            <h5><Link to="/products" className="isabaya-font-normal-static" style={{textDecoration: "none"}}>IM Sabaya Products</Link></h5>
            <ul className="nav flex-column isabaya-simple-menu" style={{paddingLeft: "40px"}}>
                <li><NavLink to={"/products/productimsabaya16"} activeClassName="active">ระบบทะเบียนกองทุนรวม</NavLink></li>
                <li><NavLink to={"/products/productimsabaya17"} activeClassName="active">ระบบทะเบียนกองทุนสำรองเลี้ยงชีพ</NavLink></li>
            </ul>
            <br />
        </div>
      );
  }
}

export default NavbarProducts