import React, { Component } from 'react'

class Works08 extends Component {

    render() {
        let pageText = {
            Header1: "โครงการพัฒนาระบบทะเบียน PromptPay",
            Header2: "ธนาคาร เกียรตินาคิน จำกัด (มหาชน)",
            MainContent: "โครงการพัฒนาระบบทะเบียน PromptPay (ชื่อเดิมคือ AnyID) โดยใช้ ASP.Net MVC, C#, NHibernate, Oracle ระบบนี้ประกอบด้วย",
            SubContent1: "ระบบ Registra Gateway พัฒนาเป็น SOAP-based Web services ที่สื่อสารธุรกรรมทะเบียนกับระบบทะเบียนกลางของ บริษัท National ITMX จำกัด โดยใช้ RESTful Web API",
            SubContent2: "ระบบเว็บจัดการทะเบียนลูกค้าที่ประสงค์จะใช้บริการธุรกรรมการเงิน PromptPay โดยพนักงานของธนาคารเป็นผู้ใช้ระบบนี้เพื่อทำธุรกรรมทะเบียน (ขึ้นทะเบียน เปลี่ยนแปลง และยกเลิก หมายเลขประจำตัวประชาชน และ/หรือ หมายเลขโทรศัพท์เคลื่อนที่) ตามความประสงค์ของลูกค้า ระบบนี้เก็บและปรับปรุงข้อมูลทะเบียนในฐานข้อมูล (Oracle) ของธนาคาร และส่งธุรกรรมทะเบียนผ่าน SOAP-based Web services ของระบบ Registra Gateway ของธนาคาร ซึ่งจะส่งต่อให้กับระบบทะเบียนกลางของ บริษัท National ITMX จำกัด (เป็นบริษัทร่วมทุนของทุกธนาคาร) ระบบนี้ยังใช้บริการของ SMS Server ของธนาคาร เพื่อส่งผลของธุรกรรมทะเบียน (สำเร็จ/ล้มเหลว/ผิดพลาด) ให้ลูกค้าที่ประสงค์จะรู้ผล",
            FooterContent: "ผังต่อไปนี้เป็นบางส่วนของการออกแบบของระบบนี้ โดยใช้ UML (Unified Modeling Language, www.uml.org)",
            FigureDescription1: "State diagram หรือวงจรชีวิตของ AnyID (หมายเลขโทรศัพท์หรือเลขโทรศัพท์เคลื่อนที่)",
            FigureDescription2: "State diagram หรือวงจรชีวิตของธุรกรรมทะเบียน AnyID (ทั้งการลงทะเบียน แก้ไข และยกเลิก)",
        };


        return (
            <div className="pl-md-5 pt-5" style={{width:"100%", height:"100%", backgroundColor: "white"}}>
                <h5 className="isabaya-font-green">{pageText.Header1}</h5>
                <h6 className="isabaya-font-green">{pageText.Header2}</h6>
                <br />
                <span className="d-block">{pageText.MainContent}</span>
                <br />
                <ul className="isabaya">
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                </ul>
                <span className="d-block">{pageText.FooterContent}</span>
                <br />
                <div className="p-3 m-auto text-center" style={{border: "2px solid rgb(156,148,1)", width:"95%"}}>
                    <img alt="" className="d-block img-fluid" style={{marginBottom: "5px"}} src={process.env.PUBLIC_URL + "/image/works0007.png"} />
                    <span style={{fontSize: "0.85rem"}}>{pageText.FigureDescription1}</span>
                </div>
                <br />
                <div className="p-3 m-auto text-center" style={{border: "2px solid rgb(156,148,1)", width:"95%"}}>
                    <img alt="" className="d-block img-fluid" style={{marginBottom: "5px"}} src={process.env.PUBLIC_URL + "/image/works0008.png"} />
                    <span style={{fontSize: "0.85rem"}}>{pageText.FigureDescription2}</span>
                </div>
                <br />
                <br />
            </div>
        );
    }
}

export default Works08