import React, { Component } from 'react'

class Products15 extends Component {

    render() {
        let pageText = {
            Header: "ระบบ Workflow (Workflow Module)",
            MainContent: "เป็นระบบที่จัดทำขึ้นเพื่ออำนวยความสะดวกด้านกระบวนการทำงานให้มีความรวดเร็วและถูกต้องแม่นยำมากยิ่งขึ้น ซึ่งใช้ทดแทนเอกสาร ที่ทำให้เกิดความล่าช้า และอาจสูญหายได้ ซึ่งระบบนี้จะเป็นตัวที่บ่งบอกกระบวนการทำงานของหน่วยงานได้เป็นอย่างดี อีกทั้งง่ายต่อการนำไปประยุกต์ใช้กับระบบอื่นๆ เช่น ระบบงานคำสั่ง ระบบการลา เป็นต้น",
        };


        return (
            <div className="pl-md-5 pt-5">
                <h5 className="isabaya-font-green" style={{marginBottom: "15px"}}>{pageText.Header}</h5>
                <span>{pageText.MainContent}</span>
                <br />
                <br />
            </div>
        );
    }
}

export default Products15