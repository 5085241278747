import React, { Component } from 'react'

class Works03 extends Component {

    render() {
        let pageText = {
            Header1: "โครงการพัฒนาระบบ Data warehouse ข้อมูลอสังหาริมทรัพย์",
            Header2: "ศูนย์ข้อมูลอสังหาริมทรัพย์ ธนาคารอาคารสงเคราะห์",
            MainContent: "",
            SubContent1: "พัฒนา SSIS packages (Microsoft SQL Server Integration Service) ในการทำ ETL (Extract, Transform, and Load) ข้อมูลเกี่ยวกับอสังหาริมทรัพย์ หลากหลายรูปแบบ (Text file, Mircosoft Excel file, Mircosoft Access database เป็นต้น) จากกว่า 20 แหล่งข้อมูล เช่น สำนักงานสถิติแห่งชาติ กรมที่ดิน กรมธนารักษ์ สำนักการโยธา กรุงเทพมหานคร เป็นต้น ระบบจะตรวจสอบความถูกต้องของข้อมูล แปลงข้อมูลให้อยู่ในรูปแบบเดียวกัน และบันทึกลงฐานข้อมูล Microsoft SQL Server",
            SubContent2: "พัฒนาระบบ web สำหรับดูข้อมูลอสังหาริมทรัพย์ในรูปแบบ กราฟชนิดต่าง ๆ และตารางข้อมูลสรุป ตามเงื่อนไขต่าง ๆ ที่ผู้ใช้กำหนด และออกรายงานจาก data warehouse โดยใช้ BI software tool ชื่อ ProClarity (ภายหลัง Microsoft ได้ซื้อบริษัทผู้ผลิต และยุติการผลิตและจำหน่ายซอฟท์แวร์นี้)",
        };


        return (
            <div className="pl-md-5 pt-5" style={{width:"100%", height:"100%", backgroundColor: "white"}}>
                <h5 className="isabaya-font-green">{pageText.Header1}</h5>
                <h6 className="isabaya-font-green">{pageText.Header2}</h6>
                <br />
                <ul className="isabaya">
                    <li><span>{pageText.SubContent1}</span></li>
                    <li><span>{pageText.SubContent2}</span></li>
                </ul>
                <br />
                <br />
            </div>
        );
    }
}

export default Works03